.text-overflow-wrap {
  text-overflow: ellipsis;
  overflow: hidden;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  .navbar {
    display: none;
  }

  .btn > .caret, .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }

  .label {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered th, .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

@font-face {
  font-family: Glyphicons Halflings;
  src: url("glyphicons-halflings-regular.cca02454.eot");
  src: url("glyphicons-halflings-regular.cca02454.eot#iefix") format("embedded-opentype"), url("glyphicons-halflings-regular.a3cea8f3.woff2") format("woff2"), url("glyphicons-halflings-regular.3a458e4a.woff") format("woff"), url("glyphicons-halflings-regular.1a33c1eb.ttf") format("truetype"), url("glyphicons-halflings-regular.6e7c7fa3.svg#glyphicons_halflingsregular") format("svg");
}

.glyphicon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
  position: relative;
  top: 1px;
  font-family: Glyphicons Halflings !important;
}

.glyphicon-asterisk:before {
  content: "*";
}

.glyphicon-plus:before {
  content: "+";
}

.glyphicon-euro:before, .glyphicon-eur:before {
  content: "€";
}

.glyphicon-minus:before {
  content: "−";
}

.glyphicon-cloud:before {
  content: "☁";
}

.glyphicon-envelope:before {
  content: "✉";
}

.glyphicon-pencil:before {
  content: "✏";
}

.glyphicon-glass:before {
  content: "";
}

.glyphicon-music:before {
  content: "";
}

.glyphicon-search:before {
  content: "";
}

.glyphicon-heart:before {
  content: "";
}

.glyphicon-star:before {
  content: "";
}

.glyphicon-star-empty:before {
  content: "";
}

.glyphicon-user:before {
  content: "";
}

.glyphicon-film:before {
  content: "";
}

.glyphicon-th-large:before {
  content: "";
}

.glyphicon-th:before {
  content: "";
}

.glyphicon-th-list:before {
  content: "";
}

.glyphicon-ok:before {
  content: "";
}

.glyphicon-remove:before {
  content: "";
}

.glyphicon-zoom-in:before {
  content: "";
}

.glyphicon-zoom-out:before {
  content: "";
}

.glyphicon-off:before {
  content: "";
}

.glyphicon-signal:before {
  content: "";
}

.glyphicon-cog:before {
  content: "";
}

.glyphicon-trash:before {
  content: "";
}

.glyphicon-home:before {
  content: "";
}

.glyphicon-file:before {
  content: "";
}

.glyphicon-time:before {
  content: "";
}

.glyphicon-road:before {
  content: "";
}

.glyphicon-download-alt:before {
  content: "";
}

.glyphicon-download:before {
  content: "";
}

.glyphicon-upload:before {
  content: "";
}

.glyphicon-inbox:before {
  content: "";
}

.glyphicon-play-circle:before {
  content: "";
}

.glyphicon-repeat:before {
  content: "";
}

.glyphicon-refresh:before {
  content: "";
}

.glyphicon-list-alt:before {
  content: "";
}

.glyphicon-lock:before {
  content: "";
}

.glyphicon-flag:before {
  content: "";
}

.glyphicon-headphones:before {
  content: "";
}

.glyphicon-volume-off:before {
  content: "";
}

.glyphicon-volume-down:before {
  content: "";
}

.glyphicon-volume-up:before {
  content: "";
}

.glyphicon-qrcode:before {
  content: "";
}

.glyphicon-barcode:before {
  content: "";
}

.glyphicon-tag:before {
  content: "";
}

.glyphicon-tags:before {
  content: "";
}

.glyphicon-book:before {
  content: "";
}

.glyphicon-bookmark:before {
  content: "";
}

.glyphicon-print:before {
  content: "";
}

.glyphicon-camera:before {
  content: "";
}

.glyphicon-font:before {
  content: "";
}

.glyphicon-bold:before {
  content: "";
}

.glyphicon-italic:before {
  content: "";
}

.glyphicon-text-height:before {
  content: "";
}

.glyphicon-text-width:before {
  content: "";
}

.glyphicon-align-left:before {
  content: "";
}

.glyphicon-align-center:before {
  content: "";
}

.glyphicon-align-right:before {
  content: "";
}

.glyphicon-align-justify:before {
  content: "";
}

.glyphicon-list:before {
  content: "";
}

.glyphicon-indent-left:before {
  content: "";
}

.glyphicon-indent-right:before {
  content: "";
}

.glyphicon-facetime-video:before {
  content: "";
}

.glyphicon-picture:before {
  content: "";
}

.glyphicon-map-marker:before {
  content: "";
}

.glyphicon-adjust:before {
  content: "";
}

.glyphicon-tint:before {
  content: "";
}

.glyphicon-edit:before {
  content: "";
}

.glyphicon-share:before {
  content: "";
}

.glyphicon-check:before {
  content: "";
}

.glyphicon-move:before {
  content: "";
}

.glyphicon-step-backward:before {
  content: "";
}

.glyphicon-fast-backward:before {
  content: "";
}

.glyphicon-backward:before {
  content: "";
}

.glyphicon-play:before {
  content: "";
}

.glyphicon-pause:before {
  content: "";
}

.glyphicon-stop:before {
  content: "";
}

.glyphicon-forward:before {
  content: "";
}

.glyphicon-fast-forward:before {
  content: "";
}

.glyphicon-step-forward:before {
  content: "";
}

.glyphicon-eject:before {
  content: "";
}

.glyphicon-chevron-left:before {
  content: "";
}

.glyphicon-chevron-right:before {
  content: "";
}

.glyphicon-plus-sign:before {
  content: "";
}

.glyphicon-minus-sign:before {
  content: "";
}

.glyphicon-remove-sign:before {
  content: "";
}

.glyphicon-ok-sign:before {
  content: "";
}

.glyphicon-question-sign:before {
  content: "";
}

.glyphicon-info-sign:before {
  content: "";
}

.glyphicon-screenshot:before {
  content: "";
}

.glyphicon-remove-circle:before {
  content: "";
}

.glyphicon-ok-circle:before {
  content: "";
}

.glyphicon-ban-circle:before {
  content: "";
}

.glyphicon-arrow-left:before {
  content: "";
}

.glyphicon-arrow-right:before {
  content: "";
}

.glyphicon-arrow-up:before {
  content: "";
}

.glyphicon-arrow-down:before {
  content: "";
}

.glyphicon-share-alt:before {
  content: "";
}

.glyphicon-resize-full:before {
  content: "";
}

.glyphicon-resize-small:before {
  content: "";
}

.glyphicon-exclamation-sign:before {
  content: "";
}

.glyphicon-gift:before {
  content: "";
}

.glyphicon-leaf:before {
  content: "";
}

.glyphicon-fire:before {
  content: "";
}

.glyphicon-eye-open:before {
  content: "";
}

.glyphicon-eye-close:before {
  content: "";
}

.glyphicon-warning-sign:before {
  content: "";
}

.glyphicon-plane:before {
  content: "";
}

.glyphicon-calendar:before {
  content: "";
}

.glyphicon-random:before {
  content: "";
}

.glyphicon-comment:before {
  content: "";
}

.glyphicon-magnet:before {
  content: "";
}

.glyphicon-chevron-up:before {
  content: "";
}

.glyphicon-chevron-down:before {
  content: "";
}

.glyphicon-retweet:before {
  content: "";
}

.glyphicon-shopping-cart:before {
  content: "";
}

.glyphicon-folder-close:before {
  content: "";
}

.glyphicon-folder-open:before {
  content: "";
}

.glyphicon-resize-vertical:before {
  content: "";
}

.glyphicon-resize-horizontal:before {
  content: "";
}

.glyphicon-hdd:before {
  content: "";
}

.glyphicon-bullhorn:before {
  content: "";
}

.glyphicon-bell:before {
  content: "";
}

.glyphicon-certificate:before {
  content: "";
}

.glyphicon-thumbs-up:before {
  content: "";
}

.glyphicon-thumbs-down:before {
  content: "";
}

.glyphicon-hand-right:before {
  content: "";
}

.glyphicon-hand-left:before {
  content: "";
}

.glyphicon-hand-up:before {
  content: "";
}

.glyphicon-hand-down:before {
  content: "";
}

.glyphicon-circle-arrow-right:before {
  content: "";
}

.glyphicon-circle-arrow-left:before {
  content: "";
}

.glyphicon-circle-arrow-up:before {
  content: "";
}

.glyphicon-circle-arrow-down:before {
  content: "";
}

.glyphicon-globe:before {
  content: "";
}

.glyphicon-wrench:before {
  content: "";
}

.glyphicon-tasks:before {
  content: "";
}

.glyphicon-filter:before {
  content: "";
}

.glyphicon-briefcase:before {
  content: "";
}

.glyphicon-fullscreen:before {
  content: "";
}

.glyphicon-dashboard:before {
  content: "";
}

.glyphicon-paperclip:before {
  content: "";
}

.glyphicon-heart-empty:before {
  content: "";
}

.glyphicon-link:before {
  content: "";
}

.glyphicon-phone:before {
  content: "";
}

.glyphicon-pushpin:before {
  content: "";
}

.glyphicon-usd:before {
  content: "";
}

.glyphicon-gbp:before {
  content: "";
}

.glyphicon-sort:before {
  content: "";
}

.glyphicon-sort-by-alphabet:before {
  content: "";
}

.glyphicon-sort-by-alphabet-alt:before {
  content: "";
}

.glyphicon-sort-by-order:before {
  content: "";
}

.glyphicon-sort-by-order-alt:before {
  content: "";
}

.glyphicon-sort-by-attributes:before {
  content: "";
}

.glyphicon-sort-by-attributes-alt:before {
  content: "";
}

.glyphicon-unchecked:before {
  content: "";
}

.glyphicon-expand:before {
  content: "";
}

.glyphicon-collapse-down:before {
  content: "";
}

.glyphicon-collapse-up:before {
  content: "";
}

.glyphicon-log-in:before {
  content: "";
}

.glyphicon-flash:before {
  content: "";
}

.glyphicon-log-out:before {
  content: "";
}

.glyphicon-new-window:before {
  content: "";
}

.glyphicon-record:before {
  content: "";
}

.glyphicon-save:before {
  content: "";
}

.glyphicon-open:before {
  content: "";
}

.glyphicon-saved:before {
  content: "";
}

.glyphicon-import:before {
  content: "";
}

.glyphicon-export:before {
  content: "";
}

.glyphicon-send:before {
  content: "";
}

.glyphicon-floppy-disk:before {
  content: "";
}

.glyphicon-floppy-saved:before {
  content: "";
}

.glyphicon-floppy-remove:before {
  content: "";
}

.glyphicon-floppy-save:before {
  content: "";
}

.glyphicon-floppy-open:before {
  content: "";
}

.glyphicon-credit-card:before {
  content: "";
}

.glyphicon-transfer:before {
  content: "";
}

.glyphicon-cutlery:before {
  content: "";
}

.glyphicon-header:before {
  content: "";
}

.glyphicon-compressed:before {
  content: "";
}

.glyphicon-earphone:before {
  content: "";
}

.glyphicon-phone-alt:before {
  content: "";
}

.glyphicon-tower:before {
  content: "";
}

.glyphicon-stats:before {
  content: "";
}

.glyphicon-sd-video:before {
  content: "";
}

.glyphicon-hd-video:before {
  content: "";
}

.glyphicon-subtitles:before {
  content: "";
}

.glyphicon-sound-stereo:before {
  content: "";
}

.glyphicon-sound-dolby:before {
  content: "";
}

.glyphicon-sound-5-1:before {
  content: "";
}

.glyphicon-sound-6-1:before {
  content: "";
}

.glyphicon-sound-7-1:before {
  content: "";
}

.glyphicon-copyright-mark:before {
  content: "";
}

.glyphicon-registration-mark:before {
  content: "";
}

.glyphicon-cloud-download:before {
  content: "";
}

.glyphicon-cloud-upload:before {
  content: "";
}

.glyphicon-tree-conifer:before {
  content: "";
}

.glyphicon-tree-deciduous:before {
  content: "";
}

.glyphicon-cd:before {
  content: "";
}

.glyphicon-save-file:before {
  content: "";
}

.glyphicon-open-file:before {
  content: "";
}

.glyphicon-level-up:before {
  content: "";
}

.glyphicon-copy:before {
  content: "";
}

.glyphicon-paste:before {
  content: "";
}

.glyphicon-alert:before {
  content: "";
}

.glyphicon-equalizer:before {
  content: "";
}

.glyphicon-king:before {
  content: "";
}

.glyphicon-queen:before {
  content: "";
}

.glyphicon-pawn:before {
  content: "";
}

.glyphicon-bishop:before {
  content: "";
}

.glyphicon-knight:before {
  content: "";
}

.glyphicon-baby-formula:before {
  content: "";
}

.glyphicon-tent:before {
  content: "⛺";
}

.glyphicon-blackboard:before {
  content: "";
}

.glyphicon-bed:before {
  content: "";
}

.glyphicon-apple:before {
  content: "";
}

.glyphicon-erase:before {
  content: "";
}

.glyphicon-hourglass:before {
  content: "⌛";
}

.glyphicon-lamp:before {
  content: "";
}

.glyphicon-duplicate:before {
  content: "";
}

.glyphicon-piggy-bank:before {
  content: "";
}

.glyphicon-scissors:before {
  content: "";
}

.glyphicon-bitcoin:before, .glyphicon-btc:before, .glyphicon-xbt:before {
  content: "";
}

.glyphicon-yen:before, .glyphicon-jpy:before {
  content: "¥";
}

.glyphicon-ruble:before, .glyphicon-rub:before {
  content: "₽";
}

.glyphicon-scale:before {
  content: "";
}

.glyphicon-ice-lolly:before {
  content: "";
}

.glyphicon-ice-lolly-tasted:before {
  content: "";
}

.glyphicon-education:before {
  content: "";
}

.glyphicon-option-horizontal:before {
  content: "";
}

.glyphicon-option-vertical:before {
  content: "";
}

.glyphicon-menu-hamburger:before {
  content: "";
}

.glyphicon-modal-window:before {
  content: "";
}

.glyphicon-oil:before {
  content: "";
}

.glyphicon-grain:before {
  content: "";
}

.glyphicon-sunglasses:before {
  content: "";
}

.glyphicon-text-size:before {
  content: "";
}

.glyphicon-text-color:before {
  content: "";
}

.glyphicon-text-background:before {
  content: "";
}

.glyphicon-object-align-top:before {
  content: "";
}

.glyphicon-object-align-bottom:before {
  content: "";
}

.glyphicon-object-align-horizontal:before {
  content: "";
}

.glyphicon-object-align-left:before {
  content: "";
}

.glyphicon-object-align-vertical:before {
  content: "";
}

.glyphicon-object-align-right:before {
  content: "";
}

.glyphicon-triangle-right:before {
  content: "";
}

.glyphicon-triangle-left:before {
  content: "";
}

.glyphicon-triangle-bottom:before {
  content: "";
}

.glyphicon-triangle-top:before {
  content: "";
}

.glyphicon-console:before {
  content: "";
}

.glyphicon-superscript:before {
  content: "";
}

.glyphicon-subscript:before {
  content: "";
}

.glyphicon-menu-left:before {
  content: "";
}

.glyphicon-menu-right:before {
  content: "";
}

.glyphicon-menu-down:before {
  content: "";
}

.glyphicon-menu-up:before {
  content: "";
}

@font-face {
  font-family: wbtvd;
  src: url("wbtvd-icons8.1eb7eea4.eot");
  src: url("wbtvd-icons8.1eb7eea4.eot#iefix") format("embedded-opentype"), url("wbtvd-icons8.d51355b2.woff2") format("woff2"), url("wbtvd-icons8.77ece871.woff") format("woff"), url("wbtvd-icons8.6ce026f2.ttf") format("truetype"), url("wbtvd-icons8.4decabd6.svg#wbtvd") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-device-pixel-ratio >= 0) {
  @font-face {
    font-family: wbtvd;
    src: url("wbtvd-icons8.4decabd6.svg#wbtvd") format("svg");
  }
}

[data-icons8]:before {
  content: attr(data-icons8);
}

[data-icons8]:before, .icons8-cut:before, .icons8-film-reel:before, .icons8-find-and-replace:before, .icons8-key-2-filled:before, .icons8-music:before, .icons8-music-video:before, .icons8-radio:before, .icons8-radio-tower:before, .icons8-talk-male:before, .icons8-tree-structure:before {
  font-variant: normal;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-rendering: optimizelegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-family: wbtvd;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.icons8-cut:before {
  content: "";
}

.icons8-film-reel:before {
  content: "";
}

.icons8-find-and-replace:before {
  content: "";
}

.icons8-key-2-filled:before {
  content: "";
}

.icons8-music:before {
  content: "";
}

.icons8-music-video:before {
  content: "";
}

.icons8-radio:before {
  content: "";
}

.icons8-radio-tower:before {
  content: "";
}

.icons8-talk-male:before {
  content: "";
}

.icons8-tree-structure:before {
  content: "";
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: #0000;
  font-size: 10px;
}

body {
  color: #333;
  background-color: #fff;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #07c;
  text-decoration: none;
}

a:hover, a:focus {
  color: #1a9fff;
  text-decoration: none;
}

a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

.img-responsive, .thumbnail > img, .thumbnail a > img, .carousel-inner > .item > img, .carousel-inner > .item > a > img {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

.img-rounded {
  border-radius: 0;
}

.img-thumbnail {
  -o-transition: all .2s ease-in-out;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  max-width: 100%;
  height: auto;
  padding: 4px;
  line-height: 1.42857;
  transition: all .2s ease-in-out;
  display: inline-block;
}

.img-circle {
  border-radius: 50%;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

[role="button"] {
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: inherit;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  color: #777;
  font-weight: normal;
  line-height: 1;
}

h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h1 .small, .h1 .small, h2 .small, .h2 .small, h3 .small, .h3 .small {
  font-size: 65%;
}

h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small, h4 .small, .h4 .small, h5 .small, .h5 .small, h6 .small, .h6 .small {
  font-size: 75%;
}

h1, .h1 {
  font-size: 36px;
}

h2, .h2 {
  font-size: 30px;
}

h3, .h3 {
  font-size: 24px;
}

h4, .h4 {
  font-size: 18px;
}

h5, .h5 {
  font-size: 14px;
}

h6, .h6 {
  font-size: 12px;
}

h3 small, .h3 small {
  color: #767676;
}

p {
  margin: 0 0 10px;
}

.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}

@media (width >= 768px) {
  .lead {
    font-size: 21px;
  }
}

small, .small {
  font-size: 85%;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-muted {
  color: #777;
}

.text-primary {
  color: #07c;
}

a.text-primary:hover, a.text-primary:focus {
  color: #005999;
}

.text-success {
  color: #3c763d;
}

a.text-success:hover, a.text-success:focus {
  color: #2b542c;
}

.text-info {
  color: #31708f;
}

a.text-info:hover, a.text-info:focus {
  color: #245269;
}

.text-warning {
  color: #8a6d3b;
}

a.text-warning:hover, a.text-warning:focus {
  color: #66512c;
}

.text-danger {
  color: #a94442;
}

a.text-danger:hover, a.text-danger:focus {
  color: #843534;
}

.text-red, .text-red:hover {
  color: #ff0004;
}

.text-default-color {
  color: #333;
}

a.text-default-color {
  color: #0075ca;
  text-decoration: none;
}

.bg-primary {
  color: #fff;
  background-color: #07c;
}

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #005999;
}

.bg-success {
  background-color: #dff0d8;
}

a.bg-success:hover, a.bg-success:focus {
  background-color: #c1e2b3;
}

.bg-info {
  background-color: #d9edf7;
}

a.bg-info:hover, a.bg-info:focus {
  background-color: #afd9ee;
}

.bg-warning {
  background-color: #fcf8e3;
}

a.bg-warning:hover, a.bg-warning:focus {
  background-color: #f7ecb5;
}

.bg-danger {
  background-color: #f2dede;
}

a.bg-danger:hover, a.bg-danger:focus {
  background-color: #e4b9b9;
}

.font-weight-normal {
  font-weight: normal !important;
}

.page-header {
  border-bottom: 1px solid #eee;
  margin: 40px 0 20px;
  padding-bottom: 9px;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 10px;
}

ul ul, ol ul, ul ol, ol ol {
  margin-bottom: 0;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  margin-left: -5px;
  padding-left: 0;
  list-style: none;
}

.list-inline > li {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}

dt, dd {
  line-height: 1.42857;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

@media (width >= 768px) {
  .dl-horizontal dt {
    float: left;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 160px;
    overflow: hidden;
  }

  .dl-horizontal dd {
    margin-left: 180px;
  }
}

abbr[title], abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #777;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

blockquote {
  border-left: 5px solid #eee;
  margin: 0 0 20px;
  padding: 10px 20px;
  font-size: 17.5px;
}

blockquote p:last-child, blockquote ul:last-child, blockquote ol:last-child {
  margin-bottom: 0;
}

blockquote footer, blockquote small, blockquote .small {
  color: #777;
  font-size: 80%;
  line-height: 1.42857;
  display: block;
}

blockquote footer:before, blockquote small:before, blockquote .small:before {
  content: "— ";
}

.blockquote-reverse, blockquote.pull-right {
  text-align: right;
  border-left: 0;
  border-right: 5px solid #eee;
  padding-left: 0;
  padding-right: 15px;
}

.blockquote-reverse footer:before, blockquote.pull-right footer:before, .blockquote-reverse small:before, blockquote.pull-right small:before, .blockquote-reverse .small:before, blockquote.pull-right .small:before {
  content: "";
}

.blockquote-reverse footer:after, blockquote.pull-right footer:after, .blockquote-reverse small:after, blockquote.pull-right small:after, .blockquote-reverse .small:after, blockquote.pull-right .small:after {
  content: " —";
}

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857;
}

code, kbd, pre, samp {
  font-family: Menlo, Monaco, Consolas, Courier New, monospace;
}

code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 0;
  padding: 2px 4px;
  font-size: 90%;
}

kbd {
  color: #fff;
  background-color: #333;
  border-radius: 0;
  padding: 2px 4px;
  font-size: 90%;
  box-shadow: inset 0 -1px #00000040;
}

kbd kbd {
  box-shadow: none;
  padding: 0;
  font-size: 100%;
  font-weight: bold;
}

pre {
  word-break: break-all;
  word-wrap: break-word;
  overflow-wrap: break-word;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 0;
  margin: 0 0 10px;
  padding: 9.5px;
  font-size: 13px;
  line-height: 1.42857;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: #0000;
  border-radius: 0;
  padding: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 5px;
  padding-right: 5px;
}

@media (width >= 768px) {
  .container {
    width: 730px;
  }
}

@media (width >= 992px) {
  .container {
    width: 950px;
  }
}

@media (width >= 1200px) {
  .container {
    width: 1150px;
  }
}

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 5px;
  padding-right: 5px;
}

.row {
  margin-left: -5px;
  margin-right: -5px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  min-height: 1px;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.6667%;
}

.col-xs-10 {
  width: 83.3333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.6667%;
}

.col-xs-7 {
  width: 58.3333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.6667%;
}

.col-xs-4 {
  width: 33.3333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.6667%;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.6667%;
}

.col-xs-pull-10 {
  right: 83.3333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.6667%;
}

.col-xs-pull-7 {
  right: 58.3333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.6667%;
}

.col-xs-pull-4 {
  right: 33.3333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.6667%;
}

.col-xs-pull-1 {
  right: 8.33333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.6667%;
}

.col-xs-push-10 {
  left: 83.3333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.6667%;
}

.col-xs-push-7 {
  left: 58.3333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.6667%;
}

.col-xs-push-4 {
  left: 33.3333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.6667%;
}

.col-xs-push-1 {
  left: 8.33333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.6667%;
}

.col-xs-offset-10 {
  margin-left: 83.3333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.6667%;
}

.col-xs-offset-7 {
  margin-left: 58.3333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.6667%;
}

.col-xs-offset-4 {
  margin-left: 33.3333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.6667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

@media (width >= 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.6667%;
  }

  .col-sm-10 {
    width: 83.3333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.6667%;
  }

  .col-sm-7 {
    width: 58.3333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.6667%;
  }

  .col-sm-4 {
    width: 33.3333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.6667%;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-pull-11 {
    right: 91.6667%;
  }

  .col-sm-pull-10 {
    right: 83.3333%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-8 {
    right: 66.6667%;
  }

  .col-sm-pull-7 {
    right: 58.3333%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-5 {
    right: 41.6667%;
  }

  .col-sm-pull-4 {
    right: 33.3333%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-2 {
    right: 16.6667%;
  }

  .col-sm-pull-1 {
    right: 8.33333%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-push-11 {
    left: 91.6667%;
  }

  .col-sm-push-10 {
    left: 83.3333%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-8 {
    left: 66.6667%;
  }

  .col-sm-push-7 {
    left: 58.3333%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-5 {
    left: 41.6667%;
  }

  .col-sm-push-4 {
    left: 33.3333%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-2 {
    left: 16.6667%;
  }

  .col-sm-push-1 {
    left: 8.33333%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }

  .col-sm-offset-11 {
    margin-left: 91.6667%;
  }

  .col-sm-offset-10 {
    margin-left: 83.3333%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-8 {
    margin-left: 66.6667%;
  }

  .col-sm-offset-7 {
    margin-left: 58.3333%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-5 {
    margin-left: 41.6667%;
  }

  .col-sm-offset-4 {
    margin-left: 33.3333%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-2 {
    margin-left: 16.6667%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }
}

@media (width >= 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.6667%;
  }

  .col-md-10 {
    width: 83.3333%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.6667%;
  }

  .col-md-7 {
    width: 58.3333%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.6667%;
  }

  .col-md-4 {
    width: 33.3333%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.6667%;
  }

  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-pull-11 {
    right: 91.6667%;
  }

  .col-md-pull-10 {
    right: 83.3333%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-8 {
    right: 66.6667%;
  }

  .col-md-pull-7 {
    right: 58.3333%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-5 {
    right: 41.6667%;
  }

  .col-md-pull-4 {
    right: 33.3333%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-2 {
    right: 16.6667%;
  }

  .col-md-pull-1 {
    right: 8.33333%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-push-11 {
    left: 91.6667%;
  }

  .col-md-push-10 {
    left: 83.3333%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-8 {
    left: 66.6667%;
  }

  .col-md-push-7 {
    left: 58.3333%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-5 {
    left: 41.6667%;
  }

  .col-md-push-4 {
    left: 33.3333%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-2 {
    left: 16.6667%;
  }

  .col-md-push-1 {
    left: 8.33333%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }

  .col-md-offset-11 {
    margin-left: 91.6667%;
  }

  .col-md-offset-10 {
    margin-left: 83.3333%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-8 {
    margin-left: 66.6667%;
  }

  .col-md-offset-7 {
    margin-left: 58.3333%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-5 {
    margin-left: 41.6667%;
  }

  .col-md-offset-4 {
    margin-left: 33.3333%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-2 {
    margin-left: 16.6667%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }
}

@media (width >= 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-11 {
    width: 91.6667%;
  }

  .col-lg-10 {
    width: 83.3333%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-8 {
    width: 66.6667%;
  }

  .col-lg-7 {
    width: 58.3333%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-5 {
    width: 41.6667%;
  }

  .col-lg-4 {
    width: 33.3333%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-2 {
    width: 16.6667%;
  }

  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-pull-11 {
    right: 91.6667%;
  }

  .col-lg-pull-10 {
    right: 83.3333%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-8 {
    right: 66.6667%;
  }

  .col-lg-pull-7 {
    right: 58.3333%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-5 {
    right: 41.6667%;
  }

  .col-lg-pull-4 {
    right: 33.3333%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-2 {
    right: 16.6667%;
  }

  .col-lg-pull-1 {
    right: 8.33333%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-push-11 {
    left: 91.6667%;
  }

  .col-lg-push-10 {
    left: 83.3333%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-8 {
    left: 66.6667%;
  }

  .col-lg-push-7 {
    left: 58.3333%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-5 {
    left: 41.6667%;
  }

  .col-lg-push-4 {
    left: 33.3333%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-2 {
    left: 16.6667%;
  }

  .col-lg-push-1 {
    left: 8.33333%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }

  .col-lg-offset-11 {
    margin-left: 91.6667%;
  }

  .col-lg-offset-10 {
    margin-left: 83.3333%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-8 {
    margin-left: 66.6667%;
  }

  .col-lg-offset-7 {
    margin-left: 58.3333%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-5 {
    margin-left: 41.6667%;
  }

  .col-lg-offset-4 {
    margin-left: 33.3333%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-2 {
    margin-left: 16.6667%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }
}

table {
  background-color: #0000;
}

caption {
  color: #777;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  vertical-align: top;
  border-top: 1px solid #ddd;
  padding: 8px;
  line-height: 1.42857;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 2px solid #ddd;
}

.table .table {
  background-color: #fff;
}

.table-condensed > thead > tr > th, .table-condensed > tbody > tr > th, .table-condensed > tfoot > tr > th, .table-condensed > thead > tr > td, .table-condensed > tbody > tr > td, .table-condensed > tfoot > tr > td {
  padding: 5px;
}

.table-bordered, .table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-striped > tbody > tr:nth-of-type(odd) a {
  color: #0075ca;
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

table col[class*="col-"] {
  float: none;
  display: table-column;
  position: static;
}

table td[class*="col-"], table th[class*="col-"] {
  float: none;
  display: table-cell;
  position: static;
}

.table > thead > tr > td.active, .table > tbody > tr > td.active, .table > tfoot > tr > td.active, .table > thead > tr > th.active, .table > tbody > tr > th.active, .table > tfoot > tr > th.active, .table > thead > tr.active > td, .table > tbody > tr.active > td, .table > tfoot > tr.active > td, .table > thead > tr.active > th, .table > tbody > tr.active > th, .table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}

.table-hover > tbody > tr > td.active:hover, .table-hover > tbody > tr > th.active:hover, .table-hover > tbody > tr.active:hover > td, .table-hover > tbody > tr:hover > .active, .table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}

.table > thead > tr > td.success, .table > tbody > tr > td.success, .table > tfoot > tr > td.success, .table > thead > tr > th.success, .table > tbody > tr > th.success, .table > tfoot > tr > th.success, .table > thead > tr.success > td, .table > tbody > tr.success > td, .table > tfoot > tr.success > td, .table > thead > tr.success > th, .table > tbody > tr.success > th, .table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover, .table-hover > tbody > tr > th.success:hover, .table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr:hover > .success, .table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}

.table > thead > tr > td.info, .table > tbody > tr > td.info, .table > tfoot > tr > td.info, .table > thead > tr > th.info, .table > tbody > tr > th.info, .table > tfoot > tr > th.info, .table > thead > tr.info > td, .table > tbody > tr.info > td, .table > tfoot > tr.info > td, .table > thead > tr.info > th, .table > tbody > tr.info > th, .table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover, .table-hover > tbody > tr > th.info:hover, .table-hover > tbody > tr.info:hover > td, .table-hover > tbody > tr:hover > .info, .table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}

.table > thead > tr > td.warning, .table > tbody > tr > td.warning, .table > tfoot > tr > td.warning, .table > thead > tr > th.warning, .table > tbody > tr > th.warning, .table > tfoot > tr > th.warning, .table > thead > tr.warning > td, .table > tbody > tr.warning > td, .table > tfoot > tr.warning > td, .table > thead > tr.warning > th, .table > tbody > tr.warning > th, .table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover, .table-hover > tbody > tr > th.warning:hover, .table-hover > tbody > tr.warning:hover > td, .table-hover > tbody > tr:hover > .warning, .table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}

.table > thead > tr > td.danger, .table > tbody > tr > td.danger, .table > tfoot > tr > td.danger, .table > thead > tr > th.danger, .table > tbody > tr > th.danger, .table > tfoot > tr > th.danger, .table > thead > tr.danger > td, .table > tbody > tr.danger > td, .table > tfoot > tr.danger > td, .table > thead > tr.danger > th, .table > tbody > tr.danger > th, .table > tfoot > tr.danger > th {
  background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover, .table-hover > tbody > tr > th.danger:hover, .table-hover > tbody > tr.danger:hover > td, .table-hover > tbody > tr:hover > .danger, .table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}

.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}

@media screen and (width <= 767px) {
  .table-responsive {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
  }

  .table-responsive > .table {
    margin-bottom: 0;
  }

  .table-responsive > .table > thead > tr > th, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }

  .table-responsive > .table-bordered {
    border: 0;
  }

  .table-responsive > .table-bordered > thead > tr > th:first-child, .table-responsive > .table-bordered > tbody > tr > th:first-child, .table-responsive > .table-bordered > tfoot > tr > th:first-child, .table-responsive > .table-bordered > thead > tr > td:first-child, .table-responsive > .table-bordered > tbody > tr > td:first-child, .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }

  .table-responsive > .table-bordered > thead > tr > th:last-child, .table-responsive > .table-bordered > tbody > tr > th:last-child, .table-responsive > .table-bordered > tfoot > tr > th:last-child, .table-responsive > .table-bordered > thead > tr > td:last-child, .table-responsive > .table-bordered > tbody > tr > td:last-child, .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }

  .table-responsive > .table-bordered > tbody > tr:last-child > th, .table-responsive > .table-bordered > tfoot > tr:last-child > th, .table-responsive > .table-bordered > tbody > tr:last-child > td, .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

.dataTable th {
  white-space: nowrap;
}

th.icon-column {
  color: #aaa;
  width: 20px;
}

th.center-column {
  text-align: center;
}

.dtr-title .glyphicon {
  color: #aaa;
}

.dtr-column td.child ul {
  width: 100%;
}

.force-center-element {
  left: 45%;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  display: block;
}

label {
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  display: inline-block;
}

input[type="search"] {
  box-sizing: border-box;
}

input[type="radio"], input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9 ;
  line-height: normal;
}

input[type="file"] {
  display: block;
}

input[type="range"] {
  width: 100%;
  display: block;
}

select[multiple], select[size] {
  height: auto;
}

input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

output {
  color: #555;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857;
  display: block;
}

.form-wrapper {
  background: #f9f9f9;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  padding: 20px;
}

.form-control {
  color: #555;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #66afe999;
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

.form-control::-ms-expand {
  background-color: #0000;
  border: 0;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  opacity: 1;
  background-color: #eee;
}

.form-control[disabled], fieldset[disabled] .form-control {
  cursor: not-allowed;
}

textarea.form-control {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: none;
}

@media screen and (-webkit-device-pixel-ratio >= 0) {
  input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
    line-height: 34px;
  }

  input[type="date"].input-sm, input[type="time"].input-sm, input[type="datetime-local"].input-sm, input[type="month"].input-sm, .input-group-sm input[type="date"], .input-group-sm input[type="time"], .input-group-sm input[type="datetime-local"], .input-group-sm input[type="month"] {
    line-height: 30px;
  }

  input[type="date"].input-lg, input[type="time"].input-lg, input[type="datetime-local"].input-lg, input[type="month"].input-lg, .input-group-lg input[type="date"], .input-group-lg input[type="time"], .input-group-lg input[type="datetime-local"], .input-group-lg input[type="month"] {
    line-height: 46px;
  }
}

.form-group {
  margin-bottom: 15px;
}

.radio, .checkbox {
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  position: relative;
}

.radio label, .checkbox label {
  cursor: pointer;
  min-height: 20px;
  margin-bottom: 0;
  padding-left: 20px;
  font-weight: normal;
}

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
  margin-left: -20px;
  margin-top: 4px \9 ;
  position: absolute;
}

.radio + .radio, .checkbox + .checkbox {
  margin-top: -5px;
}

.radio-inline, .checkbox-inline {
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 20px;
  font-weight: normal;
  display: inline-block;
  position: relative;
}

.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

input[type="radio"][disabled], input[type="checkbox"][disabled], input[type="radio"].disabled, input[type="checkbox"].disabled, fieldset[disabled] input[type="radio"], fieldset[disabled] input[type="checkbox"], .radio-inline.disabled, .checkbox-inline.disabled, fieldset[disabled] .radio-inline, fieldset[disabled] .checkbox-inline, .radio.disabled label, .checkbox.disabled label, fieldset[disabled] .radio label, fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}

.form-control-static {
  min-height: 34px;
  margin-bottom: 0;
  padding-top: 7px;
  padding-bottom: 7px;
}

.form-control-static.input-lg, .form-control-static.input-sm {
  padding-left: 0;
  padding-right: 0;
}

.input-sm {
  border-radius: 0;
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

select.input-sm {
  height: 30px;
  line-height: 30px;
}

textarea.input-sm, select[multiple].input-sm {
  height: auto;
}

.form-group-sm .form-control {
  border-radius: 0;
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px;
}

.form-group-sm textarea.form-control, .form-group-sm select[multiple].form-control {
  height: auto;
}

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.input-lg {
  border-radius: 0;
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

select.input-lg {
  height: 46px;
  line-height: 46px;
}

textarea.input-lg, select[multiple].input-lg {
  height: auto;
}

.form-group-lg .form-control {
  border-radius: 0;
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px;
}

.form-group-lg textarea.form-control, .form-group-lg select[multiple].form-control {
  height: auto;
}

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

.has-feedback {
  position: relative;
}

.has-feedback .form-control {
  padding-right: 42.5px;
}

.form-control-feedback {
  z-index: 2;
  text-align: center;
  pointer-events: none;
  width: 34px;
  height: 34px;
  line-height: 34px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.login-email, .login-password {
  color: #777;
  margin-right: 5px;
  font-size: 16px;
  top: 4px;
}

.login-password {
  pointer-events: all;
}

.input-lg + .form-control-feedback, .input-group-lg + .form-control-feedback, .form-group-lg .form-control + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.input-sm + .form-control-feedback, .input-group-sm + .form-control-feedback, .form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label {
  color: #3c763d;
}

.has-success .form-control {
  border-color: #3c763d;
  box-shadow: inset 0 1px 1px #00000013;
}

.has-success .form-control:focus {
  border-color: #2b542c;
  box-shadow: inset 0 1px 1px #00000013, 0 0 6px #67b168;
}

.has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d;
}

.has-success .form-control-feedback {
  color: #3c763d;
}

.has-warning .help-block, .has-warning .control-label, .has-warning .radio, .has-warning .checkbox, .has-warning .radio-inline, .has-warning .checkbox-inline, .has-warning.radio label, .has-warning.checkbox label, .has-warning.radio-inline label, .has-warning.checkbox-inline label {
  color: #8a6d3b;
}

.has-warning .form-control {
  border-color: #8a6d3b;
  box-shadow: inset 0 1px 1px #00000013;
}

.has-warning .form-control:focus {
  border-color: #66512c;
  box-shadow: inset 0 1px 1px #00000013, 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b;
}

.has-warning .form-control-feedback {
  color: #8a6d3b;
}

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
  color: #a94442;
}

.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px #00000013;
}

.has-error .form-control:focus {
  border-color: #843534;
  box-shadow: inset 0 1px 1px #00000013, 0 0 6px #ce8483;
}

.has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}

.has-error .form-control-feedback {
  color: #a94442;
}

.has-feedback label ~ .form-control-feedback {
  top: 25px;
}

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}

.help-block {
  color: #737373;
  margin-top: 5px;
  margin-bottom: 10px;
  display: block;
}

@media (width >= 768px) {
  .form-inline .form-group {
    vertical-align: middle;
    margin-bottom: 0;
    display: inline-block;
  }

  .form-inline .form-control {
    vertical-align: middle;
    width: auto;
    display: inline-block;
  }

  .form-inline .form-control-static {
    display: inline-block;
  }

  .form-inline .input-group {
    vertical-align: middle;
    display: inline-table;
  }

  .form-inline .input-group .input-group-addon, .form-inline .input-group .input-group-btn, .form-inline .input-group .form-control {
    width: auto;
  }

  .form-inline .input-group > .form-control {
    width: 100%;
  }

  .form-inline .control-label {
    vertical-align: middle;
    margin-bottom: 0;
  }

  .form-inline .radio, .form-inline .checkbox {
    vertical-align: middle;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
  }

  .form-inline .radio label, .form-inline .checkbox label {
    padding-left: 0;
  }

  .form-inline .radio input[type="radio"], .form-inline .checkbox input[type="checkbox"] {
    margin-left: 0;
    position: relative;
  }

  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}

.form-horizontal .radio, .form-horizontal .checkbox {
  min-height: 27px;
}

.form-horizontal .form-group {
  margin-left: -5px;
  margin-right: -5px;
}

@media (width >= 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px;
  }
}

.form-horizontal .has-feedback .form-control-feedback {
  right: 5px;
}

@media (width >= 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px;
  }

  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px;
  }
}

label em.lighter {
  font-weight: lighter;
}

.hidden-label {
  color: #000;
  font-size: 0;
  position: absolute;
}

.hidden-label-white {
  color: #fff;
  font-size: 0;
  position: absolute;
}

.btn {
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid #0000;
  border-radius: 0;
  margin-bottom: 0;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857;
  display: inline-block;
}

.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn:hover, .btn:focus, .btn.focus {
  color: #333;
  text-decoration: none;
}

.btn:active, .btn.active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px #00000020;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: .65;
  filter: alpha(opacity= 65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

a.btn.disabled, fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default:focus, .btn-default.focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}

.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-default:hover.btn-wbd-primary {
  color: #333;
  background-color: #015cfe;
  border-color: #015cfe;
}

.btn-default:active, .btn-default.active, .open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-default:active:hover, .btn-default.active:hover, .open > .dropdown-toggle.btn-default:hover, .btn-default:active:focus, .btn-default.active:focus, .open > .dropdown-toggle.btn-default:focus, .btn-default:active.focus, .btn-default.active.focus, .open > .dropdown-toggle.btn-default.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-default:active, .btn-default.active, .open > .dropdown-toggle.btn-default {
  background-image: none;
}

.btn-default.disabled:hover, .btn-default[disabled]:hover, fieldset[disabled] .btn-default:hover, .btn-default.disabled:focus, .btn-default[disabled]:focus, fieldset[disabled] .btn-default:focus, .btn-default.disabled.focus, .btn-default[disabled].focus, fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default .badge {
  color: #fff;
  background-color: #333;
}

.btn-primary {
  color: #fff;
  background-color: #07c;
  border-color: #0068b3;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #005999;
  border-color: #001e33;
}

.btn-primary:hover {
  color: #fff;
  background-color: #005999;
  border-color: #004475;
}

.btn-primary:hover.btn-wbd-primary {
  color: #fff;
  background-color: #015cfe;
  border-color: #015cfe;
}

.btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #005999;
  border-color: #004475;
}

.btn-primary:active:hover, .btn-primary.active:hover, .open > .dropdown-toggle.btn-primary:hover, .btn-primary:active:focus, .btn-primary.active:focus, .open > .dropdown-toggle.btn-primary:focus, .btn-primary:active.focus, .btn-primary.active.focus, .open > .dropdown-toggle.btn-primary.focus {
  color: #fff;
  background-color: #004475;
  border-color: #001e33;
}

.btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover, .btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus, .btn-primary.disabled.focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary.focus {
  background-color: #07c;
  border-color: #0068b3;
}

.btn-primary .badge {
  color: #07c;
  background-color: #fff;
}

.btn-wbd-primary {
  color: #fff;
  background-color: #04006c;
  border-color: #030052;
}

.btn-wbd-primary:focus, .btn-wbd-primary.focus {
  color: #fff;
  background-color: #020039;
  border-color: #000;
}

.btn-wbd-primary:hover {
  color: #fff;
  background-color: #020039;
  border-color: #010015;
}

.btn-wbd-primary:hover.btn-wbd-primary {
  color: #fff;
  background-color: #015cfe;
  border-color: #015cfe;
}

.btn-wbd-primary:active, .btn-wbd-primary.active, .open > .dropdown-toggle.btn-wbd-primary {
  color: #fff;
  background-color: #020039;
  border-color: #010015;
}

.btn-wbd-primary:active:hover, .btn-wbd-primary.active:hover, .open > .dropdown-toggle.btn-wbd-primary:hover, .btn-wbd-primary:active:focus, .btn-wbd-primary.active:focus, .open > .dropdown-toggle.btn-wbd-primary:focus, .btn-wbd-primary:active.focus, .btn-wbd-primary.active.focus, .open > .dropdown-toggle.btn-wbd-primary.focus {
  color: #fff;
  background-color: #010015;
  border-color: #000;
}

.btn-wbd-primary:active, .btn-wbd-primary.active, .open > .dropdown-toggle.btn-wbd-primary {
  background-image: none;
}

.btn-wbd-primary.disabled:hover, .btn-wbd-primary[disabled]:hover, fieldset[disabled] .btn-wbd-primary:hover, .btn-wbd-primary.disabled:focus, .btn-wbd-primary[disabled]:focus, fieldset[disabled] .btn-wbd-primary:focus, .btn-wbd-primary.disabled.focus, .btn-wbd-primary[disabled].focus, fieldset[disabled] .btn-wbd-primary.focus {
  background-color: #04006c;
  border-color: #030052;
}

.btn-wbd-primary .badge {
  color: #04006c;
  background-color: #fff;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625;
}

.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.btn-success:hover.btn-wbd-primary {
  color: #fff;
  background-color: #015cfe;
  border-color: #015cfe;
}

.btn-success:active, .btn-success.active, .open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.btn-success:active:hover, .btn-success.active:hover, .open > .dropdown-toggle.btn-success:hover, .btn-success:active:focus, .btn-success.active:focus, .open > .dropdown-toggle.btn-success:focus, .btn-success:active.focus, .btn-success.active.focus, .open > .dropdown-toggle.btn-success.focus {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.btn-success:active, .btn-success.active, .open > .dropdown-toggle.btn-success {
  background-image: none;
}

.btn-success.disabled:hover, .btn-success[disabled]:hover, fieldset[disabled] .btn-success:hover, .btn-success.disabled:focus, .btn-success[disabled]:focus, fieldset[disabled] .btn-success:focus, .btn-success.disabled.focus, .btn-success[disabled].focus, fieldset[disabled] .btn-success.focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85;
}

.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.btn-info:hover.btn-wbd-primary {
  color: #fff;
  background-color: #015cfe;
  border-color: #015cfe;
}

.btn-info:active, .btn-info.active, .open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.btn-info:active:hover, .btn-info.active:hover, .open > .dropdown-toggle.btn-info:hover, .btn-info:active:focus, .btn-info.active:focus, .open > .dropdown-toggle.btn-info:focus, .btn-info:active.focus, .btn-info.active.focus, .open > .dropdown-toggle.btn-info.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}

.btn-info:active, .btn-info.active, .open > .dropdown-toggle.btn-info {
  background-image: none;
}

.btn-info.disabled:hover, .btn-info[disabled]:hover, fieldset[disabled] .btn-info:hover, .btn-info.disabled:focus, .btn-info[disabled]:focus, fieldset[disabled] .btn-info:focus, .btn-info.disabled.focus, .btn-info[disabled].focus, fieldset[disabled] .btn-info.focus {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d;
}

.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-warning:hover.btn-wbd-primary {
  color: #fff;
  background-color: #015cfe;
  border-color: #015cfe;
}

.btn-warning:active, .btn-warning.active, .open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-warning:active:hover, .btn-warning.active:hover, .open > .dropdown-toggle.btn-warning:hover, .btn-warning:active:focus, .btn-warning.active:focus, .open > .dropdown-toggle.btn-warning:focus, .btn-warning:active.focus, .btn-warning.active.focus, .open > .dropdown-toggle.btn-warning.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}

.btn-warning:active, .btn-warning.active, .open > .dropdown-toggle.btn-warning {
  background-image: none;
}

.btn-warning.disabled:hover, .btn-warning[disabled]:hover, fieldset[disabled] .btn-warning:hover, .btn-warning.disabled:focus, .btn-warning[disabled]:focus, fieldset[disabled] .btn-warning:focus, .btn-warning.disabled.focus, .btn-warning[disabled].focus, fieldset[disabled] .btn-warning.focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.btn-danger:hover.btn-wbd-primary {
  color: #fff;
  background-color: #015cfe;
  border-color: #015cfe;
}

.btn-danger:active, .btn-danger.active, .open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.btn-danger:active:hover, .btn-danger.active:hover, .open > .dropdown-toggle.btn-danger:hover, .btn-danger:active:focus, .btn-danger.active:focus, .open > .dropdown-toggle.btn-danger:focus, .btn-danger:active.focus, .btn-danger.active.focus, .open > .dropdown-toggle.btn-danger.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}

.btn-danger:active, .btn-danger.active, .open > .dropdown-toggle.btn-danger {
  background-image: none;
}

.btn-danger.disabled:hover, .btn-danger[disabled]:hover, fieldset[disabled] .btn-danger:hover, .btn-danger.disabled:focus, .btn-danger[disabled]:focus, fieldset[disabled] .btn-danger:focus, .btn-danger.disabled.focus, .btn-danger[disabled].focus, fieldset[disabled] .btn-danger.focus {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}

.btn-gray {
  color: #333;
  background-color: silver;
  border-color: #b3b3b3;
}

.btn-gray:focus, .btn-gray.focus {
  color: #333;
  background-color: #a7a7a7;
  border-color: #737373;
}

.btn-gray:hover {
  color: #333;
  background-color: #a7a7a7;
  border-color: #959595;
}

.btn-gray:hover.btn-wbd-primary {
  color: #333;
  background-color: #015cfe;
  border-color: #015cfe;
}

.btn-gray:active, .btn-gray.active, .open > .dropdown-toggle.btn-gray {
  color: #333;
  background-color: #a7a7a7;
  border-color: #959595;
}

.btn-gray:active:hover, .btn-gray.active:hover, .open > .dropdown-toggle.btn-gray:hover, .btn-gray:active:focus, .btn-gray.active:focus, .open > .dropdown-toggle.btn-gray:focus, .btn-gray:active.focus, .btn-gray.active.focus, .open > .dropdown-toggle.btn-gray.focus {
  color: #333;
  background-color: #959595;
  border-color: #737373;
}

.btn-gray:active, .btn-gray.active, .open > .dropdown-toggle.btn-gray {
  background-image: none;
}

.btn-gray.disabled:hover, .btn-gray[disabled]:hover, fieldset[disabled] .btn-gray:hover, .btn-gray.disabled:focus, .btn-gray[disabled]:focus, fieldset[disabled] .btn-gray:focus, .btn-gray.disabled.focus, .btn-gray[disabled].focus, fieldset[disabled] .btn-gray.focus {
  background-color: silver;
  border-color: #b3b3b3;
}

.btn-gray .badge {
  color: silver;
  background-color: #333;
}

.btn-yellow {
  color: #444;
  background-color: #eb0;
  border-color: #d5a700;
}

.btn-yellow:focus, .btn-yellow.focus {
  color: #444;
  background-color: #bb9300;
  border-color: #554300;
}

.btn-yellow:hover {
  color: #444;
  background-color: #bb9300;
  border-color: #977700;
}

.btn-yellow:hover.btn-wbd-primary {
  color: #444;
  background-color: #015cfe;
  border-color: #015cfe;
}

.btn-yellow:active, .btn-yellow.active, .open > .dropdown-toggle.btn-yellow {
  color: #444;
  background-color: #bb9300;
  border-color: #977700;
}

.btn-yellow:active:hover, .btn-yellow.active:hover, .open > .dropdown-toggle.btn-yellow:hover, .btn-yellow:active:focus, .btn-yellow.active:focus, .open > .dropdown-toggle.btn-yellow:focus, .btn-yellow:active.focus, .btn-yellow.active.focus, .open > .dropdown-toggle.btn-yellow.focus {
  color: #444;
  background-color: #977700;
  border-color: #554300;
}

.btn-yellow:active, .btn-yellow.active, .open > .dropdown-toggle.btn-yellow {
  background-image: none;
}

.btn-yellow.disabled:hover, .btn-yellow[disabled]:hover, fieldset[disabled] .btn-yellow:hover, .btn-yellow.disabled:focus, .btn-yellow[disabled]:focus, fieldset[disabled] .btn-yellow:focus, .btn-yellow.disabled.focus, .btn-yellow[disabled].focus, fieldset[disabled] .btn-yellow.focus {
  background-color: #eb0;
  border-color: #d5a700;
}

.btn-yellow .badge {
  color: #eb0;
  background-color: #444;
}

.btn-link {
  color: #07c;
  border-radius: 0;
  font-weight: normal;
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled], fieldset[disabled] .btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #0000;
}

.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
  border-color: #0000;
}

.btn-link:hover, .btn-link:focus {
  color: #1a9fff;
  background-color: #0000;
  text-decoration: none;
}

.btn-link[disabled]:hover, fieldset[disabled] .btn-link:hover, .btn-link[disabled]:focus, fieldset[disabled] .btn-link:focus {
  color: #777;
  text-decoration: none;
}

.btn-lg, .btn-group-lg > .btn {
  border-radius: 0;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

.btn-sm, .btn-group-sm > .btn {
  border-radius: 0;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.btn-xs, .btn-group-xs > .btn {
  border-radius: 0;
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  height: 0;
  transition-property: height, visibility;
  transition-duration: .35s;
  transition-timing-function: ease;
  position: relative;
  overflow: hidden;
}

.caret {
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9 ;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  width: 0;
  height: 0;
  margin-left: 2px;
  display: inline-block;
}

.dropup, .dropdown {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #00000026;
  border-radius: 0;
  min-width: 160px;
  margin: 2px 0 0;
  padding: 5px 0;
  font-size: 14px;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 6px 12px #0000002d;
}

.dropdown-menu.pull-right {
  left: auto;
  right: 0;
}

.dropdown-menu .divider {
  background-color: #e5e5e5;
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
}

.dropdown-menu > li > a {
  clear: both;
  color: #333;
  white-space: nowrap;
  padding: 3px 20px;
  font-weight: normal;
  line-height: 1.42857;
  display: block;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  color: #262626;
  background-color: #f5f5f5;
  text-decoration: none;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: #fff;
  background-color: #07c;
  outline: 0;
  text-decoration: none;
}

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  color: #777;
}

.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  cursor: not-allowed;
  background-color: #0000;
  background-image: none;
  text-decoration: none;
}

.open > .dropdown-menu {
  display: block;
}

.open > a {
  outline: 0;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-download-menu {
  top: auto !important;
  left: auto !important;
}

.dropdown-header {
  color: #777;
  white-space: nowrap;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857;
  display: block;
}

.dropdown-backdrop {
  z-index: 990;
  position: fixed;
  inset: 0;
}

.pull-right > .dropdown-menu {
  left: auto;
  right: 0;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9 ;
  content: "";
}

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
  margin-bottom: 2px;
  top: auto;
  bottom: 100%;
}

@media (width >= 768px) {
  .navbar-right .dropdown-menu {
    left: auto;
    right: 0;
  }

  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto;
  }
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  float: left;
  position: relative;
}

.btn-group > .btn:hover, .btn-group-vertical > .btn:hover, .btn-group > .btn:focus, .btn-group-vertical > .btn:focus, .btn-group > .btn:active, .btn-group-vertical > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn.active {
  z-index: 2;
}

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  margin-left: -5px;
}

.btn-toolbar .btn, .btn-toolbar .btn-group, .btn-toolbar .input-group {
  float: left;
}

.btn-toolbar > .btn, .btn-toolbar > .btn-group, .btn-toolbar > .input-group {
  margin-left: 5px;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn-group {
  float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group .dropdown-toggle:active, .btn-group.open .dropdown-toggle {
  outline: 0;
}

.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
}

.btn-group > .btn-lg + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}

.btn-group.open .dropdown-toggle {
  box-shadow: inset 0 3px 5px #00000020;
}

.btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn .caret {
  margin-left: 0;
}

.btn-lg .caret {
  border-width: 5px 5px 0;
}

.dropup .btn-lg .caret {
  border-width: 0 5px 5px;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group, .btn-group-vertical > .btn-group > .btn {
  float: none;
  width: 100%;
  max-width: 100%;
  display: block;
}

.btn-group-vertical > .btn-group > .btn {
  float: none;
}

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child), .btn-group-vertical > .btn:first-child:not(:last-child), .btn-group-vertical > .btn:last-child:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-justified {
  table-layout: fixed;
  border-collapse: separate;
  width: 100%;
  display: table;
}

.btn-group-justified > .btn, .btn-group-justified > .btn-group {
  float: none;
  width: 1%;
  display: table-cell;
}

.btn-group-justified > .btn-group .btn {
  width: 100%;
}

.btn-group-justified > .btn-group .dropdown-menu {
  left: auto;
}

[data-toggle="buttons"] > .btn input[type="radio"], [data-toggle="buttons"] > .btn-group > .btn input[type="radio"], [data-toggle="buttons"] > .btn input[type="checkbox"], [data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  border-collapse: separate;
  display: table;
  position: relative;
}

.input-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}

.input-group .form-control {
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
  position: relative;
}

.input-group .form-control:focus {
  z-index: 3;
}

.input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn {
  border-radius: 0;
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

select.input-group-lg > .form-control, select.input-group-lg > .input-group-addon, select.input-group-lg > .input-group-btn > .btn {
  height: 46px;
  line-height: 46px;
}

textarea.input-group-lg > .form-control, textarea.input-group-lg > .input-group-addon, textarea.input-group-lg > .input-group-btn > .btn, select[multiple].input-group-lg > .form-control, select[multiple].input-group-lg > .input-group-addon, select[multiple].input-group-lg > .input-group-btn > .btn {
  height: auto;
}

.input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn {
  border-radius: 0;
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

select.input-group-sm > .form-control, select.input-group-sm > .input-group-addon, select.input-group-sm > .input-group-btn > .btn {
  height: 30px;
  line-height: 30px;
}

textarea.input-group-sm > .form-control, textarea.input-group-sm > .input-group-addon, textarea.input-group-sm > .input-group-btn > .btn, select[multiple].input-group-sm > .form-control, select[multiple].input-group-sm > .input-group-addon, select[multiple].input-group-sm > .input-group-btn > .btn {
  height: auto;
}

.input-group-addon, .input-group-btn, .input-group .form-control {
  display: table-cell;
}

.input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child), .input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon, .input-group-btn {
  white-space: nowrap;
  vertical-align: middle;
  width: 1%;
}

.input-group-addon {
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 0;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
}

.input-group-addon.input-sm {
  border-radius: 0;
  padding: 5px 10px;
  font-size: 12px;
}

.input-group-addon.input-lg {
  border-radius: 0;
  padding: 10px 16px;
  font-size: 18px;
}

.input-group-addon input[type="radio"], .input-group-addon input[type="checkbox"] {
  margin-top: 0;
}

.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:first-child {
  border-right: 0;
}

.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-addon:last-child {
  border-left: 0;
}

.input-group-btn {
  white-space: nowrap;
  font-size: 0;
  position: relative;
}

.input-group-btn > .btn {
  position: relative;
}

.input-group-btn > .btn + .btn {
  margin-left: -1px;
}

.input-group-btn > .btn:hover, .input-group-btn > .btn:focus, .input-group-btn > .btn:active {
  z-index: 2;
}

.input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group {
  margin-right: -1px;
}

.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px;
}

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.nav > li {
  display: block;
  position: relative;
}

.nav > li > a {
  padding: 10px 15px;
  display: block;
  position: relative;
}

.nav > li > a:hover, .nav > li > a:focus {
  background-color: #eee;
  text-decoration: none;
}

.nav > li.disabled > a {
  color: #777;
}

.nav > li.disabled > a:hover, .nav > li.disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #0000;
  text-decoration: none;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: #eee;
  border-color: #07c;
}

.nav .nav-divider {
  background-color: #e5e5e5;
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
}

.nav > li > a > img {
  max-width: none;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.nav-tabs > li > a {
  border: 1px solid #0000;
  border-radius: 0;
  margin-right: 2px;
  line-height: 1.42857;
}

.nav-tabs > li > a:hover {
  border-color: #eee #eee #ddd;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: #0000;
}

@media (width <= 767px) {
  .nav-tabs > li {
    float: none;
    width: 100%;
  }

  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    border: 1px solid #ddd;
  }
}

.nav-tabs.nav-justified {
  border-bottom: 0;
  width: 100%;
}

.nav-tabs.nav-justified > li {
  float: none;
}

.nav-tabs.nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px;
}

.nav-tabs.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (width >= 768px) {
  .nav-tabs.nav-justified > li {
    width: 1%;
    display: table-cell;
  }

  .nav-tabs.nav-justified > li > a {
    margin-bottom: 0;
  }
}

.nav-tabs.nav-justified > li > a {
  border-radius: 0;
  margin-right: 0;
}

.nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus {
  border: 1px solid #ddd;
}

@media (width >= 768px) {
  .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 0;
  }

  .nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus {
    border-bottom-color: #fff;
  }
}

.nav-pills > li {
  float: left;
}

.nav-pills > li > a {
  border-radius: 0;
}

.nav-pills > li + li {
  margin-left: 2px;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #07c;
}

.nav-stacked > li {
  float: none;
}

.nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0;
}

.nav-justified {
  width: 100%;
}

.nav-justified > li {
  float: none;
}

.nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px;
}

.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (width >= 768px) {
  .nav-justified > li {
    width: 1%;
    display: table-cell;
  }

  .nav-justified > li > a {
    margin-bottom: 0;
  }
}

.nav-tabs-justified {
  border-bottom: 0;
}

.nav-tabs-justified > li > a {
  border-radius: 0;
  margin-right: 0;
}

.nav-tabs-justified > .active > a, .nav-tabs-justified > .active > a:hover, .nav-tabs-justified > .active > a:focus {
  border: 1px solid #ddd;
}

@media (width >= 768px) {
  .nav-tabs-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 0;
  }

  .nav-tabs-justified > .active > a, .nav-tabs-justified > .active > a:hover, .nav-tabs-justified > .active > a:focus {
    border-bottom-color: #fff;
  }
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.navbar {
  border: 1px solid #0000;
  min-height: 50px;
  margin-bottom: 20px;
  position: relative;
}

@media (width >= 768px) {
  .navbar {
    border-radius: 0;
  }

  .navbar-header {
    float: left;
  }
}

.navbar-collapse {
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid #0000;
  padding-left: 5px;
  padding-right: 5px;
  overflow-x: visible;
  box-shadow: inset 0 1px #ffffff1a;
}

.navbar-collapse.in {
  overflow-y: auto;
}

@media (width >= 768px) {
  .navbar-collapse {
    box-shadow: none;
    border-top: 0;
    width: auto;
  }

  .navbar-collapse.collapse {
    padding-bottom: 0;
    height: auto !important;
    display: block !important;
    overflow: visible !important;
  }

  .navbar-collapse.in {
    overflow-y: visible;
  }

  .navbar-fixed-top .navbar-collapse, .navbar-static-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width <= 767px) {
  .hide-navbar:has(nav:not(.in)) {
    height: 0;
    transition-property: height, visibility;
    transition-duration: .35s;
    transition-timing-function: ease;
    position: relative;
    overflow: hidden;
  }
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
  max-height: 340px;
}

@media (device-width <= 480px) and (orientation: landscape) {
  .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: 200px;
  }
}

.container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
  margin-left: -5px;
  margin-right: -5px;
}

@media (width >= 768px) {
  .container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
    margin-left: 0;
    margin-right: 0;
  }
}

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
}

@media (width >= 768px) {
  .navbar-static-top {
    border-radius: 0;
  }
}

.navbar-fixed-top, .navbar-fixed-bottom {
  z-index: 2030;
  position: fixed;
  left: 0;
  right: 0;
}

@media (width >= 768px) {
  .navbar-fixed-top, .navbar-fixed-bottom {
    border-radius: 0;
  }
}

.navbar-fixed-top {
  border-width: 0 0 1px;
  top: 0;
}

.navbar-fixed-bottom {
  border-width: 1px 0 0;
  margin-bottom: 0;
  bottom: 0;
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px 5px;
  font-size: 18px;
  line-height: 20px;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-brand > img {
  display: block;
}

@media (width >= 768px) {
  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: -5px;
  }
}

.navbar-toggle {
  float: right;
  background-color: #0000;
  background-image: none;
  border: 1px solid #0000;
  border-radius: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 5px;
  padding: 9px 10px;
  position: relative;
}

.navbar-toggle:focus {
  outline: 0;
}

.navbar-toggle .icon-bar {
  border-radius: 1px;
  width: 22px;
  height: 2px;
  display: block;
}

.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}

@media (width >= 768px) {
  .navbar-toggle {
    display: none;
  }
}

.navbar-nav {
  margin: 7.5px -5px;
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

@media (width <= 767px) {
  .navbar-nav .open .dropdown-menu {
    float: none;
    box-shadow: none;
    background-color: #0000;
    border: 0;
    width: auto;
    margin-top: 0;
    position: static;
  }

  .navbar-nav .open .dropdown-menu > li > a, .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }

  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }

  .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }
}

@media (width >= 768px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }

  .navbar-nav > li {
    float: left;
  }

  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.navbar-form {
  border-top: 1px solid #0000;
  border-bottom: 1px solid #0000;
  margin: 8px -5px;
  padding: 10px 5px;
  box-shadow: inset 0 1px #ffffff1a, 0 1px #ffffff1a;
}

@media (width >= 768px) {
  .navbar-form .form-group {
    vertical-align: middle;
    margin-bottom: 0;
    display: inline-block;
  }

  .navbar-form .form-control {
    vertical-align: middle;
    width: auto;
    display: inline-block;
  }

  .navbar-form .form-control-static {
    display: inline-block;
  }

  .navbar-form .input-group {
    vertical-align: middle;
    display: inline-table;
  }

  .navbar-form .input-group .input-group-addon, .navbar-form .input-group .input-group-btn, .navbar-form .input-group .form-control {
    width: auto;
  }

  .navbar-form .input-group > .form-control {
    width: 100%;
  }

  .navbar-form .control-label {
    vertical-align: middle;
    margin-bottom: 0;
  }

  .navbar-form .radio, .navbar-form .checkbox {
    vertical-align: middle;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
  }

  .navbar-form .radio label, .navbar-form .checkbox label {
    padding-left: 0;
  }

  .navbar-form .radio input[type="radio"], .navbar-form .checkbox input[type="checkbox"] {
    margin-left: 0;
    position: relative;
  }

  .navbar-form .has-feedback .form-control-feedback {
    top: 0;
  }
}

@media (width <= 767px) {
  .navbar-form .form-group {
    margin-bottom: 5px;
  }

  .navbar-form .form-group:last-child {
    margin-bottom: 0;
  }
}

@media (width >= 768px) {
  .navbar-form {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.navbar-nav > li > .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0;
}

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  border-radius: 0;
  margin-bottom: 0;
}

.navbar-btn {
  margin-top: 8px;
  margin-bottom: 8px;
}

.navbar-btn.btn-sm {
  margin-top: 10px;
  margin-bottom: 10px;
}

.navbar-btn.btn-xs {
  margin-top: 14px;
  margin-bottom: 14px;
}

.navbar-text {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (width >= 768px) {
  .navbar-text {
    float: left;
    margin-left: 5px;
    margin-right: 5px;
  }

  .navbar-left {
    float: left !important;
  }

  .navbar-right {
    margin-right: -5px;
    float: right !important;
  }

  .navbar-right ~ .navbar-right {
    margin-right: 0;
  }
}

.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
}

.navbar-default .navbar-brand {
  color: #777;
}

.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
  color: #5e5e5e;
  background-color: #0000;
}

.navbar-default .navbar-text, .navbar-default .navbar-nav > li > a {
  color: #777;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: #333;
  background-color: #0000;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: #555;
  background-color: #e7e7e7;
}

.navbar-default .navbar-nav > .disabled > a, .navbar-default .navbar-nav > .disabled > a:hover, .navbar-default .navbar-nav > .disabled > a:focus {
  color: #ccc;
  background-color: #0000;
}

.navbar-default .navbar-toggle {
  border-color: #ddd;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: #ddd;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #888;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border-color: #e7e7e7;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  color: #555;
  background-color: #e7e7e7;
}

@media (width <= 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #777;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #333;
    background-color: #0000;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #555;
    background-color: #e7e7e7;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #ccc;
    background-color: #0000;
  }
}

.navbar-default .navbar-link {
  color: #777;
}

.navbar-default .navbar-link:hover {
  color: #333;
}

.navbar-default .btn-link {
  color: #777;
}

.navbar-default .btn-link:hover, .navbar-default .btn-link:focus {
  color: #333;
}

.navbar-default .btn-link[disabled]:hover, fieldset[disabled] .navbar-default .btn-link:hover, .navbar-default .btn-link[disabled]:focus, fieldset[disabled] .navbar-default .btn-link:focus {
  color: #ccc;
}

.navbar-inverse {
  background-color: #222;
  border-color: #080808;
}

.navbar-inverse .navbar-brand {
  color: #9d9d9d;
}

.navbar-inverse .navbar-brand:hover, .navbar-inverse .navbar-brand:focus {
  color: #fff;
  background-color: #0000;
}

.navbar-inverse .navbar-text, .navbar-inverse .navbar-nav > li > a {
  color: #9d9d9d;
}

.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
  color: #fff;
  background-color: #0000;
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: #080808;
}

.navbar-inverse .navbar-nav > .disabled > a, .navbar-inverse .navbar-nav > .disabled > a:hover, .navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #444;
  background-color: #0000;
}

.navbar-inverse .navbar-toggle {
  border-color: #333;
}

.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
  background-color: #333;
}

.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
  border-color: #101010;
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  color: #fff;
  background-color: #080808;
}

@media (width <= 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #080808;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #080808;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #9d9d9d;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #fff;
    background-color: #0000;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fff;
    background-color: #080808;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #444;
    background-color: #0000;
  }
}

.navbar-inverse .navbar-link {
  color: #9d9d9d;
}

.navbar-inverse .navbar-link:hover {
  color: #fff;
}

.navbar-inverse .btn-link {
  color: #9d9d9d;
}

.navbar-inverse .btn-link:hover, .navbar-inverse .btn-link:focus {
  color: #fff;
}

.navbar-inverse .btn-link[disabled]:hover, fieldset[disabled] .navbar-inverse .btn-link:hover, .navbar-inverse .btn-link[disabled]:focus, fieldset[disabled] .navbar-inverse .btn-link:focus {
  color: #444;
}

.breadcrumb {
  background-color: #f5f5f5;
  border-radius: 0;
  margin-bottom: 20px;
  padding: 8px 15px;
  list-style: none;
}

.breadcrumb > li {
  display: inline-block;
}

.breadcrumb > li + li:before {
  content: "/ ";
  color: #ccc;
  padding: 0 5px;
}

.breadcrumb > .active {
  color: #777;
}

.pagination {
  border-radius: 0;
  margin: 20px 0;
  padding-left: 0;
  display: inline-block;
}

.pagination > li {
  display: inline;
}

.pagination > li > a, .pagination > li > span {
  float: left;
  color: #07c;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
  padding: 6px 12px;
  line-height: 1.42857;
  text-decoration: none;
  position: relative;
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
  z-index: 2;
  color: #1a9fff;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #07c;
  border-color: #07c;
}

.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.pagination-lg > li > a, .pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

.pagination-lg > li:first-child > a, .pagination-lg > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-lg > li:last-child > a, .pagination-lg > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-sm > li > a, .pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.pagination-sm > li:first-child > a, .pagination-sm > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-sm > li:last-child > a, .pagination-sm > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pager {
  text-align: center;
  margin: 20px 0;
  padding-left: 0;
  list-style: none;
}

.pager li {
  display: inline;
}

.pager li > a, .pager li > span {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 5px 14px;
  display: inline-block;
}

.pager li > a:hover, .pager li > a:focus {
  background-color: #eee;
  text-decoration: none;
}

.pager .next > a, .pager .next > span {
  float: right;
}

.pager .previous > a, .pager .previous > span {
  float: left;
}

.pager .disabled > a, .pager .disabled > a:hover, .pager .disabled > a:focus, .pager .disabled > span {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
}

.label {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  display: inline;
}

a.label:hover, a.label:focus {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.label:empty {
  display: none;
}

.btn .label {
  position: relative;
  top: -1px;
}

.label-default {
  background-color: #777;
}

.label-default[href]:hover, .label-default[href]:focus {
  background-color: #5e5e5e;
}

.label-primary {
  background-color: #07c;
}

.label-primary[href]:hover, .label-primary[href]:focus {
  background-color: #005999;
}

.label-success {
  background-color: #5cb85c;
}

.label-success[href]:hover, .label-success[href]:focus {
  background-color: #449d44;
}

.label-info {
  background-color: #5bc0de;
}

.label-info[href]:hover, .label-info[href]:focus {
  background-color: #31b0d5;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-warning[href]:hover, .label-warning[href]:focus {
  background-color: #ec971f;
}

.label-danger {
  background-color: #d9534f;
}

.label-danger[href]:hover, .label-danger[href]:focus {
  background-color: #c9302c;
}

.badge {
  color: #fff;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #777;
  border-radius: 10px;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.btn-xs .badge, .btn-group-xs > .btn .badge {
  padding: 1px 5px;
  top: 0;
}

a.badge:hover, a.badge:focus {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.list-group-item.active > .badge, .nav-pills > .active > a > .badge {
  color: #07c;
  background-color: #fff;
}

.list-group-item > .badge {
  float: right;
}

.list-group-item > .badge + .badge {
  margin-right: 5px;
}

.nav-pills > li > a > .badge {
  margin-left: 3px;
}

.jumbotron {
  color: inherit;
  background-color: #eee;
  margin-bottom: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.jumbotron h1, .jumbotron .h1 {
  color: inherit;
}

.jumbotron p {
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200;
}

.jumbotron > hr {
  border-top-color: #d5d5d5;
}

.container .jumbotron, .container-fluid .jumbotron {
  border-radius: 0;
  padding-left: 5px;
  padding-right: 5px;
}

.jumbotron .container {
  max-width: 100%;
}

@media screen and (width >= 768px) {
  .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .container .jumbotron, .container-fluid .jumbotron {
    padding-left: 60px;
    padding-right: 60px;
  }

  .jumbotron h1, .jumbotron .h1 {
    font-size: 63px;
  }
}

.thumbnail {
  -o-transition: border .2s ease-in-out;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  margin-bottom: 20px;
  padding: 4px;
  line-height: 1.42857;
  transition: border .2s ease-in-out;
  display: block;
}

.thumbnail > img, .thumbnail a > img {
  margin-left: auto;
  margin-right: auto;
}

a.thumbnail:hover, a.thumbnail:focus, a.thumbnail.active {
  border-color: #07c;
}

.thumbnail .caption {
  color: #333;
  padding: 9px;
}

.alert {
  border: 1px solid #0000;
  border-radius: 0;
  margin-bottom: 20px;
  padding: 15px;
}

.alert h4 {
  color: inherit;
  margin-top: 0;
}

.alert .alert-link {
  font-weight: bold;
}

.alert > p, .alert > ul {
  margin-bottom: 0;
}

.alert > p + p {
  margin-top: 5px;
}

.alert-dismissable, .alert-dismissible {
  padding-right: 35px;
}

.alert-dismissable .close, .alert-dismissible .close {
  color: inherit;
  position: relative;
  top: -2px;
  right: -21px;
}

.alert-success {
  color: #3c763d;
  z-index: 10001;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  color: #31708f;
  z-index: 10001;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  color: #8a6d3b;
  z-index: 10001;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert-warning hr {
  border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  color: #a94442;
  z-index: 10001;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  background-color: #f5f5f5;
  border-radius: 0;
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: inset 0 1px 2px #0000001a;
}

.progress-bar {
  float: left;
  color: #fff;
  text-align: center;
  -o-transition: width .6s ease;
  background-color: #07c;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  transition: width .6s;
  box-shadow: inset 0 -1px #00000026;
}

.progress-striped .progress-bar, .progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 40px 40px;
}

.progress.active .progress-bar, .progress-bar.active {
  animation: 2s linear infinite progress-bar-stripes;
}

.progress-bar-success {
  background-color: #5cb85c;
}

.progress-striped .progress-bar-success {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.progress-bar-info {
  background-color: #5bc0de;
}

.progress-striped .progress-bar-info {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.progress-bar-warning {
  background-color: #f0ad4e;
}

.progress-striped .progress-bar-warning {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.progress-bar-danger {
  background-color: #d9534f;
}

.progress-striped .progress-bar-danger {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.media {
  margin-top: 15px;
}

.media:first-child {
  margin-top: 0;
}

.media, .media-body {
  zoom: 1;
  overflow: hidden;
}

.media-body {
  width: 10000px;
}

.media-object {
  display: block;
}

.media-object.img-thumbnail {
  max-width: none;
}

.media-right, .media > .pull-right {
  padding-left: 10px;
}

.media-left, .media > .pull-left {
  padding-right: 10px;
}

.media-left, .media-right, .media-body {
  vertical-align: top;
  display: table-cell;
}

.media-middle {
  vertical-align: middle;
}

.media-bottom {
  vertical-align: bottom;
}

.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.media-list {
  padding-left: 0;
  list-style: none;
}

.list-group {
  margin-bottom: 20px;
  padding-left: 0;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #ddd;
  margin-bottom: -1px;
  padding: 10px 15px;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 0;
}

a.list-group-item, button.list-group-item {
  color: #555;
}

a.list-group-item .list-group-item-heading, button.list-group-item .list-group-item-heading {
  color: #333;
}

a.list-group-item:hover, button.list-group-item:hover, a.list-group-item:focus, button.list-group-item:focus {
  color: #555;
  background-color: #f5f5f5;
  text-decoration: none;
}

button.list-group-item {
  text-align: left;
  width: 100%;
}

.list-group-item.disabled, .list-group-item.disabled:hover, .list-group-item.disabled:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #eee;
}

.list-group-item.disabled .list-group-item-heading, .list-group-item.disabled:hover .list-group-item-heading, .list-group-item.disabled:focus .list-group-item-heading {
  color: inherit;
}

.list-group-item.disabled .list-group-item-text, .list-group-item.disabled:hover .list-group-item-text, .list-group-item.disabled:focus .list-group-item-text {
  color: #777;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  z-index: 2;
  color: #fff;
  background-color: #07c;
  border-color: #07c;
}

.list-group-item.active .list-group-item-heading, .list-group-item.active:hover .list-group-item-heading, .list-group-item.active:focus .list-group-item-heading, .list-group-item.active .list-group-item-heading > small, .list-group-item.active:hover .list-group-item-heading > small, .list-group-item.active:focus .list-group-item-heading > small, .list-group-item.active .list-group-item-heading > .small, .list-group-item.active:hover .list-group-item-heading > .small, .list-group-item.active:focus .list-group-item-heading > .small {
  color: inherit;
}

.list-group-item.active .list-group-item-text, .list-group-item.active:hover .list-group-item-text, .list-group-item.active:focus .list-group-item-text {
  color: #99d4ff;
}

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}

a.list-group-item-success, button.list-group-item-success {
  color: #3c763d;
}

a.list-group-item-success .list-group-item-heading, button.list-group-item-success .list-group-item-heading {
  color: inherit;
}

a.list-group-item-success:hover, button.list-group-item-success:hover, a.list-group-item-success:focus, button.list-group-item-success:focus {
  color: #3c763d;
  background-color: #d0e9c6;
}

a.list-group-item-success.active, button.list-group-item-success.active, a.list-group-item-success.active:hover, button.list-group-item-success.active:hover, a.list-group-item-success.active:focus, button.list-group-item-success.active:focus {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;
}

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}

a.list-group-item-info, button.list-group-item-info {
  color: #31708f;
}

a.list-group-item-info .list-group-item-heading, button.list-group-item-info .list-group-item-heading {
  color: inherit;
}

a.list-group-item-info:hover, button.list-group-item-info:hover, a.list-group-item-info:focus, button.list-group-item-info:focus {
  color: #31708f;
  background-color: #c4e3f3;
}

a.list-group-item-info.active, button.list-group-item-info.active, a.list-group-item-info.active:hover, button.list-group-item-info.active:hover, a.list-group-item-info.active:focus, button.list-group-item-info.active:focus {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}

a.list-group-item-warning, button.list-group-item-warning {
  color: #8a6d3b;
}

a.list-group-item-warning .list-group-item-heading, button.list-group-item-warning .list-group-item-heading {
  color: inherit;
}

a.list-group-item-warning:hover, button.list-group-item-warning:hover, a.list-group-item-warning:focus, button.list-group-item-warning:focus {
  color: #8a6d3b;
  background-color: #faf2cc;
}

a.list-group-item-warning.active, button.list-group-item-warning.active, a.list-group-item-warning.active:hover, button.list-group-item-warning.active:hover, a.list-group-item-warning.active:focus, button.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede;
}

a.list-group-item-danger, button.list-group-item-danger {
  color: #a94442;
}

a.list-group-item-danger .list-group-item-heading, button.list-group-item-danger .list-group-item-heading {
  color: inherit;
}

a.list-group-item-danger:hover, button.list-group-item-danger:hover, a.list-group-item-danger:focus, button.list-group-item-danger:focus {
  color: #a94442;
  background-color: #ebcccc;
}

a.list-group-item-danger.active, button.list-group-item-danger.active, a.list-group-item-danger.active:hover, button.list-group-item-danger.active:hover, a.list-group-item-danger.active:focus, button.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;
}

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}

.panel {
  background-color: #fff;
  border: 1px solid #0000;
  border-radius: 0;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px #0000000d;
}

.panel-body {
  padding: 15px;
}

.panel-heading {
  border-bottom: 1px solid #0000;
  border-top-left-radius: -1px;
  border-top-right-radius: -1px;
  padding: 10px 15px;
}

.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}

.panel-title {
  color: inherit;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}

.panel-title > a, .panel-title > small, .panel-title > .small, .panel-title > small > a, .panel-title > .small > a {
  color: inherit;
}

.panel-footer {
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: -1px;
  border-bottom-left-radius: -1px;
  padding: 10px 15px;
}

.panel > .list-group, .panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}

.panel > .list-group .list-group-item, .panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}

.panel > .list-group:first-child .list-group-item:first-child, .panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: -1px;
  border-top-right-radius: -1px;
}

.panel > .list-group:last-child .list-group-item:last-child, .panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: -1px;
  border-bottom-left-radius: -1px;
}

.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.panel-heading + .list-group .list-group-item:first-child, .list-group + .panel-footer {
  border-top-width: 0;
}

.panel > .table, .panel > .table-responsive > .table, .panel > .panel-collapse > .table {
  margin-bottom: 0;
}

.panel > .table caption, .panel > .table-responsive > .table caption, .panel > .panel-collapse > .table caption {
  padding-left: 15px;
  padding-right: 15px;
}

.panel > .table:first-child, .panel > .table-responsive:first-child > .table:first-child, .panel > .table:first-child > thead:first-child > tr:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
  border-top-left-radius: -1px;
  border-top-right-radius: -1px;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-left-radius: -1px;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: -1px;
}

.panel > .table:last-child, .panel > .table-responsive:last-child > .table:last-child, .panel > .table:last-child > tbody:last-child > tr:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
  border-bottom-right-radius: -1px;
  border-bottom-left-radius: -1px;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: -1px;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: -1px;
}

.panel > .panel-body + .table, .panel > .panel-body + .table-responsive, .panel > .table + .panel-body, .panel > .table-responsive + .panel-body {
  border-top: 1px solid #ddd;
}

.panel > .table > tbody:first-child > tr:first-child th, .panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0;
}

.panel > .table-bordered, .panel > .table-responsive > .table-bordered {
  border: 0;
}

.panel > .table-bordered > thead > tr > th:first-child, .panel > .table-responsive > .table-bordered > thead > tr > th:first-child, .panel > .table-bordered > tbody > tr > th:first-child, .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child, .panel > .table-bordered > tfoot > tr > th:first-child, .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child, .panel > .table-bordered > thead > tr > td:first-child, .panel > .table-responsive > .table-bordered > thead > tr > td:first-child, .panel > .table-bordered > tbody > tr > td:first-child, .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child, .panel > .table-bordered > tfoot > tr > td:first-child, .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}

.panel > .table-bordered > thead > tr > th:last-child, .panel > .table-responsive > .table-bordered > thead > tr > th:last-child, .panel > .table-bordered > tbody > tr > th:last-child, .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child, .panel > .table-bordered > tfoot > tr > th:last-child, .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child, .panel > .table-bordered > thead > tr > td:last-child, .panel > .table-responsive > .table-bordered > thead > tr > td:last-child, .panel > .table-bordered > tbody > tr > td:last-child, .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child, .panel > .table-bordered > tfoot > tr > td:last-child, .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}

.panel > .table-bordered > thead > tr:first-child > td, .panel > .table-responsive > .table-bordered > thead > tr:first-child > td, .panel > .table-bordered > tbody > tr:first-child > td, .panel > .table-responsive > .table-bordered > tbody > tr:first-child > td, .panel > .table-bordered > thead > tr:first-child > th, .panel > .table-responsive > .table-bordered > thead > tr:first-child > th, .panel > .table-bordered > tbody > tr:first-child > th, .panel > .table-responsive > .table-bordered > tbody > tr:first-child > th, .panel > .table-bordered > tbody > tr:last-child > td, .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td, .panel > .table-bordered > tfoot > tr:last-child > td, .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td, .panel > .table-bordered > tbody > tr:last-child > th, .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th, .panel > .table-bordered > tfoot > tr:last-child > th, .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}

.panel > .table-responsive {
  border: 0;
  margin-bottom: 0;
}

.panel-group {
  margin-bottom: 20px;
}

.panel-group .panel {
  border-radius: 0;
  margin-bottom: 0;
}

.panel-group .panel + .panel {
  margin-top: 5px;
}

.panel-group .panel-heading {
  border-bottom: 0;
}

.panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #ddd;
}

.panel-group .panel-footer {
  border-top: 0;
}

.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}

.panel-default {
  border-color: #ddd;
}

.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}

.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #333;
}

.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}

.panel-primary {
  border-color: #07c;
}

.panel-primary > .panel-heading {
  color: #fff;
  background-color: #07c;
  border-color: #07c;
}

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #07c;
}

.panel-primary > .panel-heading .badge {
  color: #07c;
  background-color: #fff;
}

.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #07c;
}

.panel-success {
  border-color: #d6e9c6;
}

.panel-success > .panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d6e9c6;
}

.panel-success > .panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d;
}

.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d6e9c6;
}

.panel-info {
  border-color: #bce8f1;
}

.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bce8f1;
}

.panel-info > .panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f;
}

.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bce8f1;
}

.panel-warning {
  border-color: #faebcc;
}

.panel-warning > .panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #faebcc;
}

.panel-warning > .panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b;
}

.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #faebcc;
}

.panel-danger {
  border-color: #ebccd1;
}

.panel-danger > .panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ebccd1;
}

.panel-danger > .panel-heading .badge {
  color: #f2dede;
  background-color: #a94442;
}

.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ebccd1;
}

.embed-responsive {
  height: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.well {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 0;
  min-height: 20px;
  margin-bottom: 20px;
  padding: 19px;
  box-shadow: inset 0 1px 1px #0000000d;
}

.well blockquote {
  border-color: #00000026;
}

.well-lg {
  border-radius: 0;
  padding: 24px;
}

.well-sm {
  border-radius: 0;
  padding: 9px;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .2;
  filter: alpha(opacity= 20);
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
}

.close:hover, .close:focus {
  color: #000;
  cursor: pointer;
  opacity: .5;
  filter: alpha(opacity= 50);
  text-decoration: none;
}

button.close {
  cursor: pointer;
  -webkit-appearance: none;
  background: none;
  border: 0;
  padding: 0;
}

.modal-open {
  overflow-x: hidden;
  overflow-y: initial !important;
}

.modal {
  z-index: 10050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 1s ease-out;
  -o-transition: -o-transform 1s ease-out;
  -moz-transition: -moz-transform 1s ease-out;
  transition: transform 1s ease-out;
  transform: translate(0, -500%);
}

.modal.in .modal-dialog {
  transform: translate(0);
}

.modal-open .modal {
  overflow: hidden auto;
}

.modal-wide {
  z-index: 10050;
  width: 80%;
  margin: 60px auto;
}

.modal-wide::-webkit-scrollbar {
  display: none;
}

.modal-dialog {
  width: auto;
  margin: 10px;
  position: relative;
}

.modal-content {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: 0;
  outline: 0;
  position: relative;
  box-shadow: 0 3px 9px #00000080;
}

.modal-backdrop {
  z-index: 2040;
  background-color: #000;
  position: fixed;
  inset: 0;
}

.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity= 0);
}

.modal-backdrop.in {
  opacity: .5;
  filter: alpha(opacity= 50);
}

.modal-header {
  border-bottom: 1px solid #e5e5e5;
  padding: 15px;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857;
}

.modal-body {
  padding: 30px;
  position: relative;
}

.modal-footer {
  text-align: right;
  border-top: 1px solid #e5e5e5;
  padding: 30px;
}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (width >= 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }

  .modal-content {
    box-shadow: 0 5px 15px #00000080;
  }

  .modal-sm {
    width: 300px;
  }
}

@media (width >= 992px) {
  .modal-lg {
    width: 900px;
  }
}

.tooltip {
  z-index: 2070;
  letter-spacing: normal;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  opacity: 0;
  filter: alpha(opacity= 0);
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.42857;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.in {
  opacity: .9;
  filter: alpha(opacity= 90);
}

.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}

.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}

.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}

.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}

.tooltip-inner {
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0;
  max-width: 200px;
  padding: 3px 8px;
}

.tooltip-arrow {
  border-style: solid;
  border-color: #0000;
  width: 0;
  height: 0;
  position: absolute;
}

.tooltip.top .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #000;
  margin-left: -5px;
  bottom: 0;
  left: 50%;
}

.tooltip.top-left .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #000;
  margin-bottom: -5px;
  bottom: 0;
  right: 5px;
}

.tooltip.top-right .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #000;
  margin-bottom: -5px;
  bottom: 0;
  left: 5px;
}

.tooltip.right .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
  margin-top: -5px;
  top: 50%;
  left: 0;
}

.tooltip.left .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
  margin-top: -5px;
  top: 50%;
  right: 0;
}

.tooltip.bottom .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #000;
  margin-left: -5px;
  top: 0;
  left: 50%;
}

.tooltip.bottom-left .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #000;
  margin-top: -5px;
  top: 0;
  right: 5px;
}

.tooltip.bottom-right .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #000;
  margin-top: -5px;
  top: 0;
  left: 5px;
}

.tooltip .tooltip-menu a {
  color: #fff;
  cursor: pointer;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

.popover {
  z-index: 2060;
  letter-spacing: normal;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: 0;
  max-width: 276px;
  padding: 1px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.42857;
  text-decoration: none;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 5px 10px #0003;
}

.popover.top {
  margin-top: -10px;
}

.popover.right {
  margin-left: 10px;
}

.popover.bottom {
  margin-top: 10px;
}

.popover.left {
  margin-left: -10px;
}

.popover-title {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: -1px -1px 0 0;
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
}

.popover-content {
  padding: 9px 14px;
}

.popover > .arrow, .popover > .arrow:after {
  border-style: solid;
  border-color: #0000;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
}

.popover > .arrow {
  border-width: 11px;
}

.popover > .arrow:after {
  content: "";
  border-width: 10px;
}

.popover.top > .arrow {
  border-top-color: #00000040;
  border-bottom-width: 0;
  margin-left: -11px;
  bottom: -11px;
  left: 50%;
}

.popover.top > .arrow:after {
  content: " ";
  border-top-color: #fff;
  border-bottom-width: 0;
  margin-left: -10px;
  bottom: 1px;
}

.popover.right > .arrow {
  border-left-width: 0;
  border-right-color: #00000040;
  margin-top: -11px;
  top: 50%;
  left: -11px;
}

.popover.right > .arrow:after {
  content: " ";
  border-left-width: 0;
  border-right-color: #fff;
  bottom: -10px;
  left: 1px;
}

.popover.bottom > .arrow {
  border-top-width: 0;
  border-bottom-color: #00000040;
  margin-left: -11px;
  top: -11px;
  left: 50%;
}

.popover.bottom > .arrow:after {
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
  margin-left: -10px;
  top: 1px;
}

.popover.left > .arrow {
  border-left-color: #00000040;
  border-right-width: 0;
  margin-top: -11px;
  top: 50%;
  right: -11px;
}

.popover.left > .arrow:after {
  content: " ";
  border-left-color: #fff;
  border-right-width: 0;
  bottom: -10px;
  right: 1px;
}

.carousel {
  position: relative;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner > .item {
  -o-transition: .6s ease-in-out left;
  transition: left .6s ease-in-out;
  display: none;
  position: relative;
}

.carousel-inner > .item > img, .carousel-inner > .item > a > img {
  line-height: 1;
}

@media (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform .6s ease-in-out;
    -o-transition: -o-transform .6s ease-in-out;
    -moz-transition: -moz-transform .6s ease-in-out;
    backface-visibility: hidden;
    perspective: 1000px;
    transition: transform .6s ease-in-out;
  }

  .carousel-inner > .item.next, .carousel-inner > .item.active.right {
    left: 0;
    transform: translate3d(100%, 0, 0);
  }

  .carousel-inner > .item.prev, .carousel-inner > .item.active.left {
    left: 0;
    transform: translate3d(-100%, 0, 0);
  }

  .carousel-inner > .item.next.left, .carousel-inner > .item.prev.right, .carousel-inner > .item.active {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
}

.carousel-inner > .active, .carousel-inner > .next, .carousel-inner > .prev {
  display: block;
}

.carousel-inner > .active {
  left: 0;
}

.carousel-inner > .next, .carousel-inner > .prev {
  width: 100%;
  position: absolute;
  top: 0;
}

.carousel-inner > .next {
  left: 100%;
}

.carousel-inner > .prev {
  left: -100%;
}

.carousel-inner > .next.left, .carousel-inner > .prev.right {
  left: 0;
}

.carousel-inner > .active.left {
  left: -100%;
}

.carousel-inner > .active.right {
  left: 100%;
}

.carousel-control {
  opacity: .5;
  filter: alpha(opacity= 50);
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px #0009;
  background-color: #0000;
  border: 0;
  width: 15%;
  font-size: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.carousel-control.left {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#80000000", endColorstr= "#00000000", GradientType= 1);
  background-image: linear-gradient(to right, #00000080 0%, #0000 100%);
  background-repeat: repeat-x;
}

.carousel-control.right {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00000000", endColorstr= "#80000000", GradientType= 1);
  background-image: linear-gradient(to right, #0000 0%, #00000080 100%);
  background-repeat: repeat-x;
  left: auto;
  right: 0;
}

.carousel-control:hover, .carousel-control:focus {
  color: #fff;
  opacity: .9;
  filter: alpha(opacity= 90);
  outline: 0;
  text-decoration: none;
}

.carousel-control .icon-prev, .carousel-control .icon-next, .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right {
  z-index: 5;
  margin-top: -10px;
  display: inline-block;
  position: absolute;
  top: 50%;
}

.carousel-control .icon-prev, .carousel-control .glyphicon-chevron-left {
  margin-left: -10px;
  left: 50%;
}

.carousel-control .icon-next, .carousel-control .glyphicon-chevron-right {
  margin-right: -10px;
  right: 50%;
}

.carousel-control .icon-prev, .carousel-control .icon-next {
  width: 20px;
  height: 20px;
  font-family: serif;
  line-height: 1;
}

.carousel-control .icon-prev:before {
  content: "‹";
}

.carousel-control .icon-next:before {
  content: "›";
}

.carousel-indicators {
  z-index: 15;
  text-align: center;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  position: absolute;
  bottom: 10px;
  left: 50%;
}

.carousel-indicators li {
  text-indent: -999px;
  cursor: pointer;
  background-color: #000 \9 ;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin: 1px;
  display: inline-block;
}

.carousel-indicators .active {
  background-color: #fff;
  width: 12px;
  height: 12px;
  margin: 0;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px #0009;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

.carousel-caption .btn {
  text-shadow: none;
}

@media screen and (width >= 768px) {
  .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-prev, .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px;
  }

  .carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev {
    margin-left: -10px;
  }

  .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
    margin-right: -10px;
  }

  .carousel-caption {
    padding-bottom: 30px;
    left: 20%;
    right: 20%;
  }

  .carousel-indicators {
    bottom: 20px;
  }
}

.clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .btn-toolbar:before, .btn-toolbar:after, .btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after, .nav:before, .nav:after, .navbar:before, .navbar:after, .navbar-header:before, .navbar-header:after, .navbar-collapse:before, .navbar-collapse:after, .pager:before, .pager:after, .panel-body:before, .panel-body:after, .modal-header:before, .modal-header:after, .modal-footer:before, .modal-footer:after {
  content: " ";
  display: table;
}

.clearfix:after, .dl-horizontal dd:after, .container:after, .container-fluid:after, .row:after, .form-horizontal .form-group:after, .btn-toolbar:after, .btn-group-vertical > .btn-group:after, .nav:after, .navbar:after, .navbar-header:after, .navbar-collapse:after, .pager:after, .panel-body:after, .modal-header:after, .modal-footer:after {
  clear: both;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}

.visible-xs, .visible-sm, .visible-md, .visible-lg, .visible-xs-block, .visible-xs-inline, .visible-xs-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block {
  display: none !important;
}

@media (width <= 767px) {
  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table !important;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs, td.visible-xs {
    display: table-cell !important;
  }

  .visible-xs-block {
    display: block !important;
  }

  .visible-xs-inline {
    display: inline !important;
  }

  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table !important;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm, td.visible-sm {
    display: table-cell !important;
  }

  .visible-sm-block {
    display: block !important;
  }

  .visible-sm-inline {
    display: inline !important;
  }

  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (width >= 992px) and (width <= 1199px) {
  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table !important;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md, td.visible-md {
    display: table-cell !important;
  }

  .visible-md-block {
    display: block !important;
  }

  .visible-md-inline {
    display: inline !important;
  }

  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (width >= 1200px) {
  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table !important;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg, td.visible-lg {
    display: table-cell !important;
  }

  .visible-lg-block {
    display: block !important;
  }

  .visible-lg-inline {
    display: inline !important;
  }

  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (width <= 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (width >= 992px) and (width <= 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (width >= 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table !important;
  }

  tr.visible-print {
    display: table-row !important;
  }

  th.visible-print, td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }

  .hidden-print {
    display: none !important;
  }
}

.light-gray-container {
  background: #eee;
}

body.dark-bg {
  background: #111;
}

.responsive-container {
  background: #eee;
  width: 100%;
  position: relative;
}

.vertical-spacer {
  padding-top: 100%;
}

.vertical-spacer-16x9 {
  padding-top: 56.25%;
}

.vertical-spacer-4x3 {
  padding-top: 75%;
}

.img-container {
  text-align: center;
  font-size: 16px;
  font: 0 / 0 a;
  position: absolute;
  inset: 0;
}

.img-container:before {
  content: " ";
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.img-container img {
  vertical-align: middle;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
}

.search-criteria-container {
  background: #eee;
  min-height: 70px;
}

.search-criteria-container .navbar-nav li > a {
  color: #444;
  padding-left: 12px;
  padding-right: 12px;
}

.search-criteria-container .navbar-nav li > a.active {
  color: #000;
  background: #ddd;
  font-weight: bold;
}

.search-criteria-container .navbar-nav li > a:hover, .search-criteria-container .navbar-nav li > a:active, .search-criteria-container .navbar-nav li > a:focus, .search-criteria-container .navbar-nav li.open > a {
  background: #ddd;
}

.search-criteria-container .navbar-nav li.open {
  background: #fafafa;
}

input.search-criteria-input {
  border: 1px solid #aeaeae;
  width: 320px;
  padding: 2px 4px;
}

.filtering-options {
  position: relative;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  overflow-x: hidden;
}

a {
  color: #07c;
  cursor: pointer;
}

a:hover {
  color: #1af;
  text-decoration: none;
}

a:focus {
  outline-offset: 2px;
}

.container-zero-content {
  margin-top: 70px;
}

.header .nav > li > a:hover, .header .nav > li > a:focus, .header .nav > li.open {
  background-color: #555;
  text-decoration: none;
}

.header .nav .open > a, .header .nav .open > a:hover, .header .nav .open > a:focus {
  background-color: #0000;
  border: none;
}

.page-content {
  min-height: 500px;
  transition: height 1s;
}

.container {
  width: 100%;
  max-width: 1400px;
}

.bg-wb-blue {
  color: #fff;
  background: #07c;
}

.bg-wb-blue:hover, .bg-wb-blue:active {
  color: #fff;
  background: #08d;
}

.bg-white {
  background: #fff;
}

.bg-gray {
  background: #ccc;
}

.bg-light-gray {
  background: #eee;
}

.navbar-toggle {
  position: absolute;
  top: 10px;
  right: 0;
}

.caret-right {
  vertical-align: middle;
  border-top: 4px solid #0000;
  border-bottom: 4px solid #0000;
  border-left: 4px solid;
  width: 0;
  height: 0;
  display: inline-block;
}

.btn-secondary {
  color: #444;
  background: #e9e9e9;
}

.btn-secondary .caret-right {
  border-color: #0000 #0000 #0000 #09e;
}

.header-logo {
  vertical-align: middle;
  float: none;
  width: auto;
  height: 50px;
  display: inline-block;
}

.billboard-foreground {
  float: none;
  z-index: 100;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0;
}

.billboard-overlay {
  background-image: url("billboard-overlay-3.91b74b9e.png");
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.billboard-overlay-container {
  width: 40%;
  height: 100%;
  margin-left: 10%;
  display: table;
}

.billboard-overlay-cell {
  vertical-align: middle;
  color: #fff;
  width: 50%;
  display: table-cell;
}

.billboard-overlay-cell h1 {
  margin-top: 0;
  margin-bottom: 15px;
  padding: 0;
  font-weight: bold;
}

.billboard-overlay-cell p {
  margin-bottom: 15px;
  font-size: 1.8rem;
  line-height: 175%;
}

.billboard-overlay-cell button {
  margin-top: 10px;
}

.billboard-caption {
  opacity: .9;
  text-shadow: none;
  color: #333;
  background: #fff;
  margin-bottom: 10px;
  padding: 10px 20px;
  display: inline-block;
}

.billboard-divider {
  border-right: 1px solid #ccc;
  width: 0;
  height: 24px;
  margin: -8px 10px;
  display: inline-block;
}

.billboard-cta-icon {
  vertical-align: -3px;
  font-size: 130%;
}

.btn .glyphicon + span {
  padding-left: .5rem;
}

.img-responsive, .thumbnail > img, .thumbnail a > img, .carousel-inner > .item > img, .carousel-inner > .item > a > img, .thumbnail > img, .thumbnail a > img, .carousel-inner > .item > img, .carousel-inner > .item > a > img {
  width: 100%;
}

.carousel-control {
  max-width: 100px;
}

.carousel-control span.glyphicon {
  width: 4vw;
  font-size: 4vw;
}

.carousel-control .glyphicon-chevron-left {
  margin-left: 2vw;
  left: 0;
}

.carousel-control .glyphicon-chevron-right {
  margin-right: 2vw;
  right: 0;
}

.thumbnail-container {
  margin-bottom: 20px;
}

.thumbnail-container img {
  border: 1px solid #ddd;
}

.thumbnail-container p {
  padding-top: 10px;
  font-size: 16px;
}

.thumbnail-container p small {
  color: #888;
}

.thumbnail-title {
  margin: 10px 0 20px;
  display: block;
}

.video-thumbnail {
  cursor: pointer;
  display: block;
  position: relative;
}

.video-thumbnail.active img {
  border: 4px solid #09e;
}

.thumbnail-play-icon {
  opacity: .5;
  text-shadow: 0 0 0 5px #000;
  color: #fff;
  margin-top: -30px;
  margin-left: -30px;
  font-size: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.video-thumbnail:hover .thumbnail-play-icon {
  opacity: 1;
}

.carousel video {
  display: block;
}

.tout-content {
  width: 49%;
  display: inline-block;
}

.tout-content.tout-text {
  vertical-align: middle;
  padding-left: 20px;
}

.tout-container {
  display: table;
}

.tout-cell {
  vertical-align: middle;
  width: 50%;
  display: table-cell;
}

.no-transition {
  transition: none;
}

.twitter-typeahead {
  display: block !important;
}

.typeahead, .tt-query, .tt-hint {
  border: 2px solid #ccc;
  border-radius: 0;
  outline: none;
  width: 396px;
  height: 30px;
  padding: 8px 12px;
  font-size: 24px;
  line-height: 30px;
}

.typeahead {
  background-color: #fff;
}

.typeahead:focus {
  border: 2px solid #0097cf;
}

.tt-query {
  box-shadow: inset 0 1px 1px #00000013;
}

.tt-hint {
  color: #999;
}

.tt-menu {
  background-color: #fff;
  border: 1px solid #0003;
  border-radius: 0;
  width: 422px;
  margin: 40px 0;
  padding: 8px 0;
  box-shadow: 0 5px 10px #0003;
  z-index: 1100 !important;
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 18px;
  line-height: 24px;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #09e;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #09e;
}

.tt-suggestion p {
  margin: 0;
}

.gist {
  font-size: 14px;
}

@media screen and (width <= 480px) {
  .tout-cell {
    vertical-align: middle;
    width: 100%;
    display: block;
  }
}

@media screen and (width <= 768px) {
  .carousel-inner {
    background: #f9f9f9;
    height: 500px;
  }

  .carousel-control {
    max-height: 250px;
  }

  .billboard-overlay {
    background: none;
  }

  .billboard-overlay-container {
    top: 300px;
    width: 100% !important;
  }

  .billboard-overlay-cell {
    vertical-align: bottom;
    color: #333;
    width: 80%;
    padding-bottom: 20px;
    display: block;
    position: absolute;
    top: 280px;
  }

  .billboard-overlay-cell h1 {
    font-size: 24px !important;
  }

  .item {
    height: auto;
  }

  .item img, .item video {
    float: right;
    width: auto !important;
    max-width: 300% !important;
    height: 250px !important;
  }

  .carousel-caption {
    padding: 0;
    bottom: 5px;
  }

  .carousel-caption .caption-text, .carousel-indicators {
    display: none;
  }

  .header .navbar-nav > li > a {
    color: #fff;
  }

  a.dropdown-toggle {
    font-size: 120%;
  }

  .header .dropdown-menu > li > a {
    color: #eee;
  }

  .header .dropdown-menu > li > a:hover, .header .dropdown-menu > li > a:focus {
    color: #fff;
    background: #666;
  }

  .dropdown-menu .divider {
    background-color: #666;
  }

  .dropdown-header {
    color: #999;
    text-transform: uppercase;
  }

  .collapsed-search {
    background: none;
    border: none;
    font-size: 18px;
    transition: top .3s;
    position: absolute;
    top: -40px;
    right: 50px;
  }

  .header-scroll .collapsed-search {
    top: 20px;
  }

  .header-search-icon {
    display: none !important;
  }
}

@media screen and (width <= 992px) {
  .tout-content {
    width: 100%;
    padding: 0;
  }

  .billboard-overlay-cell h1 {
    font-size: 3.6rem;
  }

  .billboard-overlay-cell p {
    font-size: 1.4rem;
    line-height: 140%;
  }
}

@media screen and (width >= 768px) {
  .navbar-nav > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .header .navbar-nav > li > a {
    color: #fff;
  }

  .header .navbar-nav > li > a:hover {
    background: #555;
  }

  .navbar-nav > .divider, .header .navbar-nav > .divider {
    height: 70px;
  }

  .collapsed-search {
    display: none;
  }
}

@media screen and (width >= 1400px) {
  .billboard-container {
    background: #111;
    margin-bottom: 60px;
    position: relative;
  }

  .billboard-background {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .billboard-foreground {
    top: 40px;
  }
}

.item-count-link {
  cursor: pointer;
}

.page-content.press-site {
  margin-top: 70px;
  margin-bottom: 70px;
}

.page-content.press-site .title-header {
  margin-bottom: 0;
}

.page-content.press-site .responsive-container {
  background-color: #fff;
}

.cart-parent-assets .display-results-grid .img-container img {
  max-height: 60%;
}

.show-archived-packages {
  padding-top: 5px;
}

.show-archived-packages small {
  margin-left: 5px;
}

.react-datepicker__year-select {
  border: 1px solid #aeaeae;
}

.List\(n\) {
  padding-inline-start: 40px;
}

.margin-x-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.width-hundred-percent {
  width: 100%;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

td .block {
  white-space: nowrap;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.margin-x-0 {
  margin-left: 0;
  margin-right: 0;
}

.margin-y-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-all-0 {
  margin: 0;
}

.padding-x-0 {
  padding-left: 0;
  padding-right: 0;
}

.padding-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-all-0 {
  padding: 0;
}

.margin-x-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.margin-y-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-all-5 {
  margin: 5px;
}

.padding-x-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.padding-y-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-right-5 {
  padding-right: 5px;
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-all-5 {
  padding: 5px;
}

.margin-x-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.margin-y-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-all-10 {
  margin: 10px;
}

.padding-x-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.padding-y-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-all-10 {
  padding: 10px;
}

.margin-x-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.margin-y-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-all-15 {
  margin: 15px;
}

.padding-x-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.padding-y-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-right-15 {
  padding-right: 15px;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.padding-left-15 {
  padding-left: 15px;
}

.padding-all-15 {
  padding: 15px;
}

.margin-x-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.margin-y-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-all-20 {
  margin: 20px;
}

.padding-x-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.padding-y-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-right-20 {
  padding-right: 20px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-all-20 {
  padding: 20px;
}

.margin-x-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.margin-y-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-right-25 {
  margin-right: 25px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-left-25 {
  margin-left: 25px;
}

.margin-all-25 {
  margin: 25px;
}

.padding-x-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.padding-y-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.padding-top-25 {
  padding-top: 25px;
}

.padding-right-25 {
  padding-right: 25px;
}

.padding-bottom-25 {
  padding-bottom: 25px;
}

.padding-left-25 {
  padding-left: 25px;
}

.padding-all-25 {
  padding: 25px;
}

.margin-x-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.margin-y-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-all-30 {
  margin: 30px;
}

.padding-x-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.padding-y-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-right-30 {
  padding-right: 30px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-left-30 {
  padding-left: 30px;
}

.padding-all-30 {
  padding: 30px;
}

.margin-x-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.margin-y-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.margin-top-35 {
  margin-top: 35px;
}

.margin-right-35 {
  margin-right: 35px;
}

.margin-bottom-35 {
  margin-bottom: 35px;
}

.margin-left-35 {
  margin-left: 35px;
}

.margin-all-35 {
  margin: 35px;
}

.padding-x-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.padding-y-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.padding-top-35 {
  padding-top: 35px;
}

.padding-right-35 {
  padding-right: 35px;
}

.padding-bottom-35 {
  padding-bottom: 35px;
}

.padding-left-35 {
  padding-left: 35px;
}

.padding-all-35 {
  padding: 35px;
}

.margin-x-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.margin-y-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-all-40 {
  margin: 40px;
}

.padding-x-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.padding-y-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-right-40 {
  padding-right: 40px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-left-40 {
  padding-left: 40px;
}

.padding-all-40 {
  padding: 40px;
}

.margin-x-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.margin-y-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.margin-top-45 {
  margin-top: 45px;
}

.margin-right-45 {
  margin-right: 45px;
}

.margin-bottom-45 {
  margin-bottom: 45px;
}

.margin-left-45 {
  margin-left: 45px;
}

.margin-all-45 {
  margin: 45px;
}

.padding-x-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.padding-y-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.padding-top-45 {
  padding-top: 45px;
}

.padding-right-45 {
  padding-right: 45px;
}

.padding-bottom-45 {
  padding-bottom: 45px;
}

.padding-left-45 {
  padding-left: 45px;
}

.padding-all-45 {
  padding: 45px;
}

.margin-x-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.margin-y-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-right-50 {
  margin-right: 50px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-left-50 {
  margin-left: 50px;
}

.margin-all-50 {
  margin: 50px;
}

.padding-x-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.padding-y-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-right-50 {
  padding-right: 50px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-left-50 {
  padding-left: 50px;
}

.padding-all-50 {
  padding: 50px;
}

.margin-x-55 {
  margin-left: 55px;
  margin-right: 55px;
}

.margin-y-55 {
  margin-top: 55px;
  margin-bottom: 55px;
}

.margin-top-55 {
  margin-top: 55px;
}

.margin-right-55 {
  margin-right: 55px;
}

.margin-bottom-55 {
  margin-bottom: 55px;
}

.margin-left-55 {
  margin-left: 55px;
}

.margin-all-55 {
  margin: 55px;
}

.padding-x-55 {
  padding-left: 55px;
  padding-right: 55px;
}

.padding-y-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}

.padding-top-55 {
  padding-top: 55px;
}

.padding-right-55 {
  padding-right: 55px;
}

.padding-bottom-55 {
  padding-bottom: 55px;
}

.padding-left-55 {
  padding-left: 55px;
}

.padding-all-55 {
  padding: 55px;
}

@media (width >= 768px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (width <= 991px) {
  .hidden-sm-down {
    display: none !important;
  }
}

.visible-block-sm-up {
  display: none;
}

@media (width >= 768px) {
  .visible-block-sm-up {
    display: block !important;
  }
}

.visible-block-sm-down {
  display: none;
}

@media (width <= 991px) {
  .visible-block-sm-down {
    display: block !important;
  }
}

.visible-inline-sm-up {
  display: none;
}

@media (width >= 768px) {
  .visible-inline-sm-up {
    display: inline !important;
  }
}

@media (width <= 991px) {
  .visible-inline-sm-down {
    display: inline !important;
  }
}

.visible-inline-block-sm-up {
  display: none;
}

@media (width >= 768px) {
  .visible-inline-block-sm-up {
    display: inline-block !important;
  }
}

.visible-inline-sm-down {
  display: none;
}

@media (width <= 991px) {
  .visible-inline-sm-down {
    display: inline-block !important;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .pull-left-sm {
    float: left;
  }
}

@media (width >= 768px) {
  .pull-left-sm-up {
    float: left;
  }
}

@media (width <= 991px) {
  .pull-left-sm-down {
    float: left;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .pull-right-sm {
    float: right;
  }
}

@media (width >= 768px) {
  .pull-right-sm-up {
    float: right;
  }
}

@media (width <= 991px) {
  .pull-right-sm-down {
    float: right;
  }
}

@media (width >= 992px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (width <= 1199px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (width >= 992px) {
  .visible-block-md-up {
    display: block !important;
  }
}

@media (width <= 1199px) {
  .visible-block-md-down {
    display: block !important;
  }
}

@media (width >= 992px) {
  .visible-inline-md-up {
    display: inline !important;
  }
}

@media (width <= 1199px) {
  .visible-inline-md-down {
    display: inline !important;
  }
}

@media (width >= 992px) {
  .visible-inline-block-md-up {
    display: inline-block !important;
  }
}

@media (width <= 1199px) {
  .visible-inline-md-down {
    display: inline-block !important;
  }
}

@media (width <= 767px) {
  .pull-right-xs {
    float: right;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .pull-right-sm {
    float: right;
  }
}

@media (width >= 768px) {
  .pull-right-sm-up {
    float: right;
  }
}

@media (width <= 991px) {
  .pull-right-sm-down {
    float: right;
  }
}

@media (width >= 992px) and (width <= 1199px) {
  .pull-right-md {
    float: right;
  }
}

@media (width >= 992px) {
  .pull-right-md-up {
    float: right;
  }
}

@media (width <= 1199px) {
  .pull-right-md-down {
    float: right;
  }
}

@media (width >= 1200px) {
  .pull-right-lg {
    float: right;
  }
}

.auth-link, .action-link {
  cursor: pointer;
}

.auth-link.pl-0, .action-link.pl-0 {
  padding-left: 0;
}

.login-page {
  background-color: #222;
}

.login-page #wrapper-top {
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.login-page #content {
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.login-page #wrapper-bottom {
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.login-page .login-form-wrapper {
  padding: 200px 0 170px;
}

.login-page .form-box {
  text-align: left;
  background: #e9e9e9;
  margin-top: 10px;
  padding: 40px 30px 30px;
}

.login-page .form-control {
  box-shadow: none;
  height: 40px;
}

.login-page .login-links {
  color: #000;
  text-align: center;
  margin: 10px auto 0;
  padding: 5px 0 0;
  font-size: 12px;
  display: inline-block !important;
}

.login-page .login-footer {
  text-align: center;
  color: #fff;
  padding-top: 10px;
}

@media (width <= 767px) {
  .login-page .login-form-wrapper {
    padding: 10px 0 0;
  }
}

#header-search {
  border: none;
  height: 40px;
  padding-left: 10px;
  font-size: 24px;
  background: #fff !important;
}

.header-search .dropdown-toggle {
  box-shadow: none;
  background: #eee;
  border: none;
  height: 40px;
}

.header-search-container .container {
  position: relative;
}

.header-search-addon {
  color: #fff;
  background-color: #5128f2;
  border: none;
  padding: 7px;
}

.header-search-container {
  background-color: #444;
  height: 70px;
  margin-top: 70px;
}

.header-search {
  z-index: 900;
  background-color: #0022af;
  width: 100%;
  height: 70px;
  padding: 15px;
  transition: top .5s;
  position: relative;
  top: 0;
}

.header-search.header-search-fixed {
  margin-top: 0;
  position: fixed;
  top: 70px;
  left: 0;
}

.header-search.header-search-fixed.header-search-hidden {
  top: 0;
}

.header-search a {
  color: #fff;
}

.header-nav-link a, .header-nav-link .search-icon {
  text-transform: uppercase;
  font-size: 130%;
  font-weight: 900;
}

.header-nav-link .search-icon {
  color: #fff;
  padding: 25px 15px;
}

.header-nav-requests {
  background: #5128f2;
  margin-top: -10px;
  margin-bottom: -10px;
  padding: 10px;
  display: inline-block;
}

.search-icon {
  transition: top .3s;
  top: -50px;
}

.header-scroll .search-icon {
  top: 4px;
}

@media (width >= 768px) and (width <= 812px) {
  .header-scroll .search-icon {
    top: 70px;
  }

  .header-search.header-search-fixed {
    margin-top: 0;
    position: fixed;
    top: 140px;
    left: 0;
  }
}

@media (width >= 813px) and (width <= 991px) {
  .header-search.header-search-fixed {
    margin-top: 0;
    position: fixed;
    top: 70px;
    left: 0;
  }
}

html.double-footer {
  min-height: 100%;
  padding-bottom: 120px;
  position: relative;
}

html.single-footer {
  min-height: 100%;
  padding-bottom: 60px;
  position: relative;
}

.double-footer .sticky-footer-container {
  width: 100%;
  min-height: 120px;
  margin-top: -120px;
  position: absolute;
  top: 100%;
}

.single-footer .sticky-footer-container {
  width: 100%;
  min-height: 60px;
  margin-top: -60px;
  position: absolute;
  top: 100%;
}

.home-icon {
  color: #888;
  background: #fff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  padding: 22px 20px;
  font-size: 50px;
  position: relative;
}

.homepage-alt-header {
  padding: 20px;
}

.homepage-alt-header img {
  max-width: 100%;
}

.homepage-video-wrapper {
  background: #111;
  margin-bottom: 20px;
  overflow: hidden;
}

.homepage-video-container {
  padding: 0;
  position: relative;
  box-shadow: 0 0 30px #000;
}

.playlist-control {
  color: #fff;
  opacity: .2;
  cursor: pointer;
  margin-top: -30px;
  font-size: 60px;
  transition: opacity .2s;
  position: absolute;
  top: 50%;
}

.homepage-video-description {
  color: #ccc;
  padding: 20px 15px;
}

.homepage-video-description h1 {
  margin-top: 0;
  font-weight: 100;
}

.homepage-video-description #homepage-video-title {
  color: #fff;
}

.playlist-control:hover {
  opacity: 1;
}

#prev-playlist {
  margin-right: 20px;
  right: 100%;
}

#next-playlist {
  margin-left: 20px;
  left: 100%;
}

.home-icon .far {
  position: absolute;
  top: 25px;
  left: 25px;
}

.title-header {
  color: #fff;
  background: #04006c;
  overflow: hidden;
}

.title-header .container {
  padding: 0;
  position: relative;
}

.title-header-text {
  color: #fff;
  padding: 20px 15px;
}

.title-header-text h1, .title-header-text h2 {
  color: #ccc;
  margin-top: 0;
  font-weight: 100;
}

.title-header-text h1 strong, .title-header-text h2 strong {
  color: #fff;
}

.title-header-text img {
  max-width: 650px;
  max-height: 60px;
}

.title-header-text span {
  color: #fff;
  padding-left: 10px;
  font-size: x-large;
}

.title-nav {
  color: #fff;
  background: #5128f2;
}

.title-nav hr {
  border-color: #888;
  margin: 20px 15px 0;
}

.title-nav-link {
  color: #fff;
  font-size: 18px;
}

.title-nav-link:hover {
  color: #fff;
}

.title-nav-link.active {
  color: #fff;
  font-weight: bold;
}

.title-nav-collapsed {
  text-align: left;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  position: relative;
}

.title-nav .nav > li > a:hover, .title-nav .nav > li > a:focus {
  color: #fff;
  background: #04006c;
}

@media screen and (width <= 991px) {
  .auto-columns {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    width: 300px;
  }
}

@media screen and (width >= 992px) {
  .auto-columns {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    width: 500px;
  }
}

.video-banner-container {
  position: relative;
}

.video-banner-btns {
  position: absolute;
  top: -90px;
  right: 25px;
}

.video-banner-btns button {
  background: #00000080;
  border: none;
  border-radius: 40px;
  outline: none;
  padding: 15px;
  font-size: 40px;
}

.img-video-still {
  background-image: url("video-still-overlay.978bfbe7.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.media-overlay-alert {
  background-color: #0006;
  width: 100%;
  min-width: 400px;
  padding-top: 10px;
  padding-left: 10%;
  padding-right: 10%;
  position: fixed;
  top: 70px;
}

.media-overlay-alert.no-background {
  background-color: #0000;
  padding-top: 0;
}

.pswp--open .img-video-still {
  right: 100px;
}

.pswp--open .media-overlay-alert {
  background-color: #0000;
}

.title-beetlejuice-content a {
  color: #6b508a;
}

.title-beetlejuice-content .btn-primary {
  color: #fff;
  background: #6b508a;
  border-color: #501753;
}

.title-beetlejuice-content {
  background-image: url("beetlejuice-bg.70134ddc.jpg");
  background-position-x: center;
}

.title-beetlejuice-content .container {
  background-color: #fff;
  position: relative;
}

.title-beetlejuice-decoration-top-left {
  width: 20vw;
  position: absolute;
  top: -5vh;
  right: 100%;
}

.title-beetlejuice-decoration-bottom-right {
  width: 50vw;
  position: absolute;
  bottom: 0;
  left: 100%;
}

.title-beetlejuice-talent .img-responsive {
  background-image: url("beetlejuice-title-frame.6c771eff.jpg");
  background-size: cover;
  padding: 16% 6%;
}

.wbtvd-elf-takeover .title-elf-container {
  background: #ffffffe6;
  position: relative;
  box-shadow: 0 0 100px 100px #ffffffe6;
}

.wbtvd-elf-takeover .title-elf-content a {
  color: #19592f;
}

.wbtvd-elf-takeover .title-elf-content .btn-primary, .wbtvd-elf-takeover .title-elf-wrapper .btn-primary {
  color: #fff;
  background: #19592f;
  border-color: #114924;
}

.wbtvd-elf-takeover .title-elf-content .btn-secondary {
  color: #19592f;
}

.wbtvd-elf-takeover .title-elf-content {
  background-image: url("elf-title-bg.3a8a634c.jpg");
  background-position-x: center;
  overflow: hidden;
}

.wbtvd-elf-takeover .title-elf-content .container {
  background-color: #fff;
  position: relative;
}

.wbtvd-elf-takeover .title-elf-decoration-top-left {
  width: 20vw;
  position: absolute;
  top: -5vh;
  right: 100%;
}

.wbtvd-elf-takeover .title-elf-decoration-bottom-right {
  width: 50vw;
  position: absolute;
  bottom: 0;
  left: 100%;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(0);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.wbtvd-elf-takeover .responsive-container {
  background: none !important;
}

.thumbnail-image {
  text-align: center;
  width: 100%;
  height: 15px;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
}

.thumbnail-image.needs-approval {
  color: #000;
  background: #ffff54;
}

.thumbnail-image.restricted {
  color: #fff;
  background: #d00;
}

.thumbnail-hover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.thumbnail-menu {
  color: #fff;
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 60%;
  margin: 20% 0;
  display: none;
}

.thumbnail-hover:hover, .thumbnail-hover.hovered {
  background: #000000e6;
}

.thumbnail-hover:hover .thumbnail-menu, .thumbnail-hover.hovered .thumbnail-menu {
  display: table;
}

.thumbnail-menu td:hover {
  color: #ccc;
}

.thumbnail-display-option {
  vertical-align: middle;
  cursor: pointer;
  font-size: 24px;
}

.thumbnail-display-option.active {
  color: #09e;
}

.thumbnail-caption-short {
  margin: 20px 0;
  display: none;
}

.thumbnail-caption-long {
  display: none;
}

.thumbnail-caption-long.innerHTML p {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  color: #333;
  font-family: Roboto, sans-serif;
  line-height: 1.42857;
  padding-top: 0 !important;
  font-size: 14px !important;
}

.image-download-links {
  display: none;
}

.image-overlay {
  text-align: center;
  transform-style: preserve-3d;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.image-overlay:before {
  content: " ";
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.image-overlay-container {
  min-width: 500px;
  max-width: calc(100% - 100px);
  display: inline-block;
  position: relative;
}

@media (width <= 767px) {
  .image-overlay-container {
    min-width: 0;
    max-width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: inline-block;
    position: static;
  }
}

.image-overlay-container img {
  vertical-align: middle;
  width: auto;
  margin: 0 auto;
  max-width: calc(100% - 200px) !important;
  max-height: calc(100% - 200px) !important;
}

@media (width <= 767px) {
  .image-overlay-container img {
    max-height: 100% !important;
  }
}

.image-overlay-description {
  color: #fff;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
  line-height: 100%;
  display: block;
  position: absolute;
}

@media (width <= 767px) {
  .image-overlay-description {
    background: #000c;
    width: 100%;
    padding: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.image-overlay-permission {
  display: none;
}

.image-overlay-permission.active {
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
  display: block;
}

.image-overlay-permission.needs-approval.active {
  color: #ffff54;
}

.image-overlay-permission.restricted.active {
  color: #d00;
}

.image-overlay-icon-container a {
  color: #fff;
  margin: 0 0 20px 20px;
  font-size: 24px;
}

.thumbnail-tooltip + .tooltip {
  opacity: 1;
}

.thumbnail-tooltip + .tooltip > .tooltip-inner {
  background-color: #fff;
  box-shadow: 0 0 10px #000;
}

.thumbnail-tooltip + .tooltip > .tooltip-inner a {
  color: #000;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

.thumbnail-tooltip + .tooltip > .tooltip-arrow {
  border-bottom-color: #fff;
}

.thumbnail-tooltip + .tooltip.top .tooltip-inner {
  background-color: #fff;
}

.thumbnail-tooltip + .tooltip.top .tooltip-arrow {
  border-top-color: #fff;
}

.thumbnail-menu tr {
  height: 33.33% !important;
}

.grid-options {
  font-size: 14px;
}

.grid-options-segment {
  vertical-align: middle;
  height: 30px;
  display: inline-block;
}

.grid-options .vertical-divider {
  vertical-align: middle;
  border-right: 1px solid #ccc;
  height: 30px;
  margin: 0 10px;
  display: inline-block;
}

.grid-options select.show-per-page {
  width: auto;
  display: inline-block;
}

.grid-pagination {
  vertical-align: middle;
  display: inline-block;
}

.grid-pagination-container {
  width: 120px;
  font-size: 12px;
}

.video-thumbnail {
  position: relative;
  overflow: hidden;
}

.video-thumbnail:hover {
  overflow: unset;
}

.video-thumbnail .video-toolbar {
  opacity: 0;
  text-align: center;
  color: #fff;
  letter-spacing: 10px;
  z-index: 300;
  width: 100%;
  height: 45px;
  padding-top: 10px;
  font-size: 24px;
  transition: all .5s;
  position: absolute;
  top: -50px;
}

.video-stack-count {
  color: #fff;
  text-align: center;
  z-index: 1;
  background-color: #04006c;
  width: 100%;
  padding: 3px;
  position: absolute;
  bottom: 0;
}

.video-new-label {
  color: #fff;
  text-align: right;
  z-index: 200;
  background-image: url("video-new-label.1f7ba39b.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 50px;
  height: 50px;
  padding: 5px 5px 0 0;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
}

.video-playback-number {
  color: #fff;
  text-align: center;
  background: #0009;
  width: 100%;
  padding: 3px;
  position: absolute;
  bottom: 0;
}

.video-playback-number.red {
  cursor: not-allowed;
  background: #8009;
}

.video-thumbnail .video-toolbar span {
  cursor: pointer;
  margin: 0 5px;
}

.video-thumbnail.hovered .video-toolbar, .video-thumbnail:hover .video-toolbar {
  opacity: 1;
  top: 0;
}

.video-thumbnail .video-play {
  z-index: 200;
  opacity: 0;
  width: 100%;
  height: calc(100% - 45px);
  transition: all .5s;
  position: absolute;
  top: 150%;
}

.video-thumbnail.hovered .video-play, .video-thumbnail:hover .video-play {
  opacity: 1;
  top: 45px;
}

.video-thumbnail .video-thumbnail-overlay {
  opacity: 0;
  z-index: 100;
  background: #0006;
  width: 100%;
  height: 100%;
  transition: opacity 1s;
  position: absolute;
  top: 0;
  left: 0;
}

.video-thumbnail.hovered .video-thumbnail-overlay, .video-thumbnail:hover .video-thumbnail-overlay {
  opacity: 1;
}

.hover-play-icon {
  text-shadow: 0 0 0 5px #000;
  color: #fff;
  margin-top: -50px;
  margin-left: -30px;
  font-size: 60px;
  position: absolute;
  top: 55%;
  left: 50%;
}

.video-stack-container {
  height: 8px;
}

.video-stack {
  height: 4px;
  margin: 0 auto;
}

.video-stack-top {
  background: #ccc;
  width: calc(100% - 20px);
}

.video-stack-bottom {
  background: #888;
  width: calc(100% - 10px);
}

.video-thumbnail-info {
  padding-top: 15px;
}

.video-overlay {
  z-index: 10000;
  background: #000000e6;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.video-overlay-controls {
  text-align: right;
  color: #fff;
  z-index: 10001;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.video-overlay-bg {
  text-align: center;
  transform-style: preserve-3d;
  z-index: 10000;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.video-overlay-bg:before {
  content: " ";
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.video-overlay-wrapper {
  text-align: center;
  width: 1600px;
  max-width: calc(100% - 40px);
  display: inline-block;
  position: relative;
}

.video-overlay-container {
  vertical-align: middle;
  width: auto;
  padding-top: 44px;
  display: inline-block;
  width: 100% !important;
}

.video-toolbar .tooltip-menu button, .video-overlay-controls button, .video-overlay-controls .white {
  color: #fff;
  background: none;
  border: none;
  width: 44px;
  height: 44px;
  font-size: 18px;
}

.video-player-wrapper {
  text-align: left;
  max-width: 140vh;
}

.video-overlay-single .video-player-wrapper {
  float: none;
  width: 100%;
  display: inline-block;
}

.multi-video-playlist {
  display: none;
}

.video-overlay-list .multi-video-playlist {
  display: block;
}

.video-overlay-playlist {
  vertical-align: text-top;
  height: calc(100vh - 120px);
  padding: 0;
  display: block;
  overflow: auto;
}

.video-overlay-playlist .glyphicon {
  float: left;
  margin-bottom: 3px;
  margin-right: 20px;
  font-size: 24px;
}

.video-overlay-playlist li {
  float: none;
  text-align: left;
  padding: 0;
  display: inline;
}

.video-overlay-playlist input {
  display: none;
}

.video-overlay-playlist label {
  cursor: pointer;
  box-sizing: border-box;
  color: #c6c6c6;
  vertical-align: middle;
  background: #2c2c2c;
  border: 1px solid #555;
  border-top: none;
  width: 100%;
  margin: 0;
  padding: 8px 15px;
  font-size: 12px;
  display: block;
  overflow: hidden;
}

.video-overlay-playlist > :first-child label {
  border-top: 1px solid #555;
}

.video-overlay-playlist li label:hover, .video-overlay-playlist input:checked + label {
  color: #09e;
  background: #171717;
}

.video-toolbar .tooltip {
  opacity: 1;
}

.video-toolbar .tooltip > .tooltip-inner {
  color: #333;
  background-color: #fff;
  box-shadow: 0 0 10px #000;
}

.video-toolbar .tooltip > .tooltip-inner button {
  color: #000;
  width: 100%;
  height: auto;
  margin: auto;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

.video-toolbar .tooltip > .tooltip-arrow {
  border-bottom-color: #fff;
}

.video-toolbar .tooltip.top .tooltip-inner {
  background-color: #fff;
}

.video-toolbar .tooltip.top .tooltip-arrow {
  border-top-color: #fff;
}

.videos-sort-button.btn.dropdown-toggle.btn-default {
  box-shadow: none;
  border: none;
  color: #09e !important;
  background: none !important;
}

.videos-sort-button.btn.dropdown-toggle.btn-default:hover, .videos-sort-button.btn.dropdown-toggle.btn-default:active {
  background: none;
}

.merch-item {
  background: #f7f7f7;
  margin-bottom: 10px;
  padding: 20px;
}

.merch-title {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.merch-price {
  font-weight: bold;
}

.billboard-foreground.hub-billboard {
  max-width: 1400px;
  top: 0;
}

.billboard-container {
  margin-bottom: 0;
}

label.disabled {
  color: #aaa;
  font-style: italic;
}

.display-type-switch {
  color: #aaa;
  cursor: pointer;
  vertical-align: middle;
  font-size: 24px;
}

.display-type-switch:hover {
  color: #09e;
}

.display-type-switch.active {
  color: #444;
}

.datatable-responsive th {
  white-space: nowrap;
}

.index-column {
  width: 40px;
}

.not-available-item {
  opacity: .5;
}

.cart-parent-table {
  table-layout: fixed;
  width: 100%;
  font-size: 13px;
}

@media (width <= 767px) {
  .cart-parent-table > thead > tr > th {
    display: none;
  }

  .cart-parent-table > tbody > tr, .cart-parent-table > tbody > tr > td {
    display: block;
  }
}

.cart-title-column {
  width: 13%;
}

@media (width <= 767px) {
  .cart-title-column {
    width: 100%;
    display: block;
  }
}

.cart-ro-column {
  width: 7%;
}

@media (width <= 767px) {
  .cart-ro-column {
    width: 100%;
    display: block;
  }

  .cart-parent-number {
    float: left;
    border-bottom: 1px solid #ddd;
    width: 10%;
  }

  .cart-parent-title {
    float: left;
    border-bottom: 1px solid #ddd;
    width: 90%;
  }
}

.h1.font-scale-10 {
  transform: scale(1, 1.1);
}

.share-overlay {
  z-index: 100;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.share-overlay-controls {
  text-align: right;
  color: #fff;
  z-index: 10001;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.share-overlay-bg {
  text-align: center;
  transform-style: preserve-3d;
  z-index: 100;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.share-overlay-bg:before {
  content: " ";
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.share-overlay-wrapper {
  text-align: center;
  max-width: calc(100% - 40px);
  display: inline-block;
  position: relative;
}

.share-overlay-container {
  vertical-align: middle;
  width: auto;
  display: inline-block;
  width: 100% !important;
}

.share-overlay-controls button {
  color: #fff;
  background: none;
  border: none;
  width: 44px;
  height: 44px;
  font-size: 18px;
}

.share-player-wrapper {
  text-align: left;
  float: none;
  width: 100%;
  max-width: calc(140vh - 140px);
  display: inline-block;
}

.share-video-wrapper {
  width: 1600px;
}

.share-image {
  max-width: 100%;
  max-height: calc(70vh - 100px);
  margin-top: -10vh;
}

.image-share-description {
  color: #fff;
  text-align: left;
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
  line-height: 100%;
  display: inline-block;
  position: absolute;
}

.image-share-restricted {
  display: none;
}

.image-share-restricted.active {
  color: #e33;
  margin-bottom: 15px;
  font-weight: bold;
  display: block;
}

.image-share-text a {
  color: #fff;
}

#player {
  position: relative;
}

#player:not(.jwplayer) .jw-aspect {
  background: #777 radial-gradient(#0000 0%, #000000a6 100%);
  padding-top: 56.25%;
}

#player:not(.jwplayer) .loading {
  color: #fff;
  text-align: center;
  background-color: #00000040;
  border-radius: 5px;
  width: 48px;
  height: 48px;
  margin: auto;
  padding: 4px;
  position: absolute;
  inset: 0;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

.animated.flipOutX, .animated.flipOutY, .animated.bounceIn, .animated.bounceOut {
  animation-duration: .75s;
}

@keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  animation-name: rubberBand;
}

@keyframes shake {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  animation-name: shake;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0);
  }
}

.swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  animation-name: tada;
}

@keyframes wobble {
  from {
    transform: none;
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: none;
  }
}

.wobble {
  animation-name: wobble;
}

@keyframes jello {
  from, 11.1%, to {
    transform: none;
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

.bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.bounceOut {
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes flip {
  from {
    animation-timing-function: ease-out;
    transform: perspective(400px)rotate3d(0, 1, 0, -360deg);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -190deg);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -170deg);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(.95, .95, .95);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px);
  }
}

.animated.flip {
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInX {
  animation-name: flipInX;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInY {
  animation-name: flipInY;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.flipOutX {
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

.flipOutY {
  animation-name: flipOutY;
  backface-visibility: visible !important;
}

@keyframes lightSpeedIn {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    opacity: 1;
    transform: skewX(-5deg);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  from {
    transform-origin: center;
    opacity: 0;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    transform-origin: center;
    opacity: 1;
    transform: none;
  }
}

.rotateIn {
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }

  to {
    transform-origin: 0 100%;
    opacity: 1;
    transform: none;
  }
}

.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: none;
  }
}

.rotateInDownRight {
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    transform-origin: 0 100%;
    opacity: 1;
    transform: none;
  }
}

.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, -90deg);
  }

  to {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: none;
  }
}

.rotateInUpRight {
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  from {
    transform-origin: center;
    opacity: 1;
  }

  to {
    transform-origin: center;
    opacity: 0;
    transform: rotate3d(0, 0, 1, 200deg);
  }
}

.rotateOut {
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  from {
    transform-origin: 0 100%;
    opacity: 1;
  }

  to {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }
}

.rotateOutDownLeft {
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  from {
    transform-origin: 100% 100%;
    opacity: 1;
  }

  to {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.rotateOutDownRight {
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  from {
    transform-origin: 0 100%;
    opacity: 1;
  }

  to {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.rotateOutUpLeft {
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  from {
    transform-origin: 100% 100%;
    opacity: 1;
  }

  to {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, 90deg);
  }
}

.rotateOutUpRight {
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 80deg);
  }

  40%, 80% {
    transform-origin: 0 0;
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.hinge {
  animation-name: hinge;
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate3d(0, 0, 1, 120deg);
  }
}

.rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    transform-origin: bottom;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.zoomOutDown {
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform-origin: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.zoomOutLeft {
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform-origin: 100%;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.zoomOutRight {
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    transform-origin: bottom;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.zoomOutUp {
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
}

.pswp {
  -ms-touch-action: none;
  touch-action: none;
  z-index: 2041;
  -webkit-text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  outline: none;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.pswp * {
  box-sizing: border-box;
}

.pswp img {
  max-width: none;
}

.pswp i {
  top: 12px;
}

.pswp--animate_opacity {
  opacity: .001;
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--open {
  display: block;
}

.pswp--zoom-allowed .pswp__img {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.pswp--zoomed-in .pswp__img {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.pswp--dragging .pswp__img {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.pswp__bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  background: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(0);
}

.pswp__scroll-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.pswp__container, .pswp__zoom-wrap {
  -ms-touch-action: none;
  touch-action: none;
  position: absolute;
  inset: 0;
}

.pswp__container, .pswp__img {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.pswp__zoom-wrap {
  transform-origin: 0 0;
  -webkit-transition: -webkit-transform .333s cubic-bezier(.4, 0, .22, 1);
  width: 100%;
  transition: transform .333s cubic-bezier(.4, 0, .22, 1);
  position: absolute;
}

.pswp__bg {
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--animated-in .pswp__bg, .pswp--animated-in .pswp__zoom-wrap {
  transition: none;
}

.pswp__container, .pswp__zoom-wrap {
  -webkit-backface-visibility: hidden;
}

.pswp__item {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.pswp__img {
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__img--placeholder {
  -webkit-backface-visibility: hidden;
}

.pswp__img--placeholder--blank {
  background: #222;
}

.pswp--ie .pswp__img {
  top: 0;
  left: 0;
  width: 100% !important;
  height: auto !important;
}

.pswp__error-msg {
  text-align: center;
  color: #ccc;
  width: 100%;
  margin-top: -8px;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 50%;
  left: 0;
}

.pswp__error-msg a {
  color: #ccc;
  text-decoration: underline;
}

.pswp__button {
  cursor: pointer;
  -webkit-appearance: none;
  float: right;
  opacity: .75;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  border: 0;
  width: 44px;
  height: 44px;
  margin: 0;
  padding: 0;
  transition: opacity .2s;
  display: block;
  position: relative;
  overflow: visible;
}

.pswp__button:focus, .pswp__button:hover {
  opacity: 1;
}

.pswp__button:focus i, .pswp__button:hover i {
  color: #fff;
  opacity: 1;
}

.pswp__button:active {
  opacity: .9;
  outline: none;
}

.pswp__button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.pswp__ui--over-close .pswp__button--close {
  opacity: 1;
}

.pswp__button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-size: 264px 88px;
  width: 44px;
  height: 44px;
  font-family: Glyphicons Halflings;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}

.pswp__button--arrow--left:before, .pswp__button--arrow--right:before {
  width: 5vw;
  height: 5vw;
  padding-top: .5vw;
  font-size: 4vw;
}

.pswp__button--close:before {
  content: "";
}

.pswp__button--share {
  background: none !important;
}

.pswp__button--share:before {
  content: "";
}

.pswp__button--image-share:before {
  content: "";
}

.pswp__button--cart:before {
  content: "";
}

.pswp__button--remove:before {
  content: "";
}

.pswp__button--fs {
  display: none;
}

.pswp--supports-fs .pswp__button--fs {
  display: block;
}

.pswp--supports-fs .pswp__button--fs:before {
  content: "";
}

.pswp--fs .pswp__button--fs:before {
  content: "";
}

.pswp__button--zoom {
  background-position: -88px 0;
  display: none;
}

.pswp--zoom-allowed .pswp__button--zoom {
  display: block;
}

.pswp--zoomed-in .pswp__button--zoom {
  background-position: -132px 0;
}

.pswp--touch .pswp__button--arrow--left, .pswp--touch .pswp__button--arrow--right {
  visibility: hidden;
}

.pswp__button--arrow--left, .pswp__button--arrow--right {
  color: #fff;
  background: none;
  width: 5vw;
  height: 5vw;
  margin-top: -2.5vw;
  position: absolute;
  top: 50%;
}

.pswp__button--arrow--left {
  left: 0;
}

.pswp__button--arrow--right {
  right: 0;
}

.pswp__button--arrow--left:before, .pswp__button--arrow--right:before {
  background-color: #0000004d;
  position: absolute;
}

.pswp__button--arrow--left:before {
  content: "";
  left: 6px;
}

.pswp__button--arrow--right:before {
  content: "";
  right: 6px;
}

.pswp__counter, .pswp__share-modal {
  -webkit-user-select: none;
  user-select: none;
}

.pswp__share-modal {
  z-index: 1600;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  background: #00000080;
  width: 100%;
  height: 100%;
  padding: 10px;
  transition: opacity .25s ease-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__share-modal--hidden {
  display: none;
}

.pswp__share-tooltip {
  z-index: 1620;
  -webkit-transition: -webkit-transform .25s;
  -webkit-backface-visibility: hidden;
  will-change: transform;
  background: #fff;
  border-radius: 2px;
  width: auto;
  transition: transform .25s;
  display: block;
  position: absolute;
  top: 56px;
  right: 44px;
  transform: translateY(6px);
  box-shadow: 0 2px 5px #00000040;
}

.pswp__share-tooltip a {
  color: #000;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

.pswp__share-tooltip a:hover {
  color: #000;
  text-decoration: none;
}

.pswp__share-tooltip a:first-child {
  border-radius: 2px 2px 0 0;
}

.pswp__share-tooltip a:first-child:before {
  content: "";
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  pointer-events: none;
  border: 6px solid #0000;
  border-bottom-color: #fff;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: -12px;
  right: 15px;
}

.pswp__share-tooltip a:last-child {
  border-radius: 0 0 2px 2px;
}

.pswp__share-modal--fade-in {
  opacity: 1;
}

.pswp__share-modal--fade-in .pswp__share-tooltip {
  transform: translateY(0);
}

.pswp--touch .pswp__share-tooltip a {
  padding: 16px 12px;
}

a.pswp__share--facebook:before {
  content: "";
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  pointer-events: none;
  border: 6px solid #0000;
  border-bottom-color: #fff;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: -12px;
  right: 15px;
}

a.pswp__share--facebook:hover {
  color: #fff;
  background: #3e5c9a;
}

a.pswp__share--facebook:hover:before {
  border-bottom-color: #3e5c9a;
}

a.pswp__share--twitter:hover {
  color: #fff;
  background: #55acee;
}

a.pswp__share--pinterest:hover {
  color: #ce272d;
  background: #ccc;
}

a.pswp__share--download:hover {
  background: #ddd;
}

.pswp__counter {
  color: #fff;
  opacity: .75;
  height: 44px;
  padding: 0 10px;
  font-size: 13px;
  line-height: 44px;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__caption {
  width: 100%;
  min-height: 44px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pswp__caption small {
  color: #bbb;
  font-size: 11px;
}

.pswp__caption__center {
  text-align: left;
  color: #ccc;
  max-width: 420px;
  margin: 0 auto;
  padding: 10px;
  font-size: 13px;
  line-height: 20px;
}

.pswp__caption--empty {
  display: none;
}

.pswp__caption--fake {
  visibility: hidden;
}

.pswp__preloader {
  opacity: 0;
  will-change: opacity;
  direction: ltr;
  width: 44px;
  height: 44px;
  margin-left: -22px;
  transition: opacity .25s ease-out;
  position: absolute;
  top: 0;
  left: 50%;
}

.pswp__preloader__icn {
  width: 20px;
  height: 20px;
  margin: 12px;
}

.pswp__preloader--active, .pswp--css_animation .pswp__preloader--active {
  opacity: 1;
}

.pswp--css_animation .pswp__preloader--active .pswp__preloader__icn {
  animation: .5s linear infinite clockwise;
}

.pswp--css_animation .pswp__preloader--active .pswp__preloader__donut {
  animation: 1s cubic-bezier(.4, 0, .22, 1) infinite donut-rotate;
}

.pswp--css_animation .pswp__preloader__icn {
  opacity: .75;
  background: none;
  width: 14px;
  height: 14px;
  margin: 0;
  position: absolute;
  top: 15px;
  left: 15px;
}

.pswp--css_animation .pswp__preloader__cut {
  width: 7px;
  height: 14px;
  position: relative;
  overflow: hidden;
}

.pswp--css_animation .pswp__preloader__donut {
  box-sizing: border-box;
  background: none;
  border: 2px solid #fff;
  border-color: #fff #fff #0000 #0000;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (width <= 1024px) {
  .pswp__preloader {
    float: right;
    margin: 0;
    position: relative;
    top: auto;
    left: auto;
  }
}

@keyframes clockwise {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes donut-rotate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-140deg);
  }

  100% {
    transform: rotate(0);
  }
}

.pswp__ui {
  -webkit-font-smoothing: auto;
  visibility: visible;
  opacity: 1;
  z-index: 1550;
}

.pswp__top-bar {
  width: 100%;
  height: 44px;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__caption, .pswp__top-bar, .pswp--has_mouse .pswp__button--arrow--left, .pswp--has_mouse .pswp__button--arrow--right {
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--has_mouse .pswp__button--arrow--left, .pswp--has_mouse .pswp__button--arrow--right {
  visibility: visible;
}

.pswp__top-bar, .pswp__caption {
  background-color: #00000080;
}

.pswp__ui--fit .pswp__top-bar, .pswp__ui--fit .pswp__caption {
  background-color: #0000004d;
}

.pswp__ui--idle .pswp__top-bar, .pswp__ui--idle .pswp__button--arrow--left, .pswp__ui--idle .pswp__button--arrow--right {
  opacity: 0;
}

.pswp__ui--hidden .pswp__top-bar, .pswp__ui--hidden .pswp__caption, .pswp__ui--hidden .pswp__button--arrow--left, .pswp__ui--hidden .pswp__button--arrow--right {
  opacity: .001;
}

.pswp__ui--one-slide .pswp__button--arrow--left, .pswp__ui--one-slide .pswp__button--arrow--right, .pswp__ui--one-slide .pswp__counter {
  display: none;
}

.pswp__element--disabled {
  display: none !important;
}

.pswp--minimal--dark .pswp__top-bar {
  background: none;
}

/*# sourceMappingURL=index.06bedd82.css.map */
